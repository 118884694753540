import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { PassDataService } from 'src/app/shared/services/pass-data.service';
import { SNACKBAR_RESULT } from 'src/app/shared/data/data';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss']
})
export class ProfilePhotoComponent implements OnInit {
  public currentPic = 'assets/images/user-none.png';
  public isLoading = false;
  public userId: string;
  public profile: any;
  public form: FormGroup;
  public imageURLprofile: string;
  public rootUrl = environment.rootUrl;

  constructor(
    private snackbarService: SnackbarService,
    private profileService: ProfileService,
    private passDataService: PassDataService,
  ) { }

  ngOnInit(): void {
    this.profile = JSON.parse(localStorage.getItem('profile'));
    this.userId = this.profile.uuid;
    this.form = new FormGroup({
      profilePic: new FormControl(null),
    });
    this.getProfileImage();
  }

  showPreview(event, action, side?) {
    if (action === 'add') {
      const file = (event.target as HTMLInputElement).files[0];
      let newForm = new FormData();
      newForm.append('icon', file);
      this.uploadProfilePic(newForm);
      const reader = new FileReader();

      this.form.get('profilePic').updateValueAndValidity();
      reader.onload = () => {
        this.currentPic = reader.result as string;

      };
      reader.readAsDataURL(file);
    }
  }

  public async uploadProfilePic(data: FormData) {
    //se sigue revisando
    this.isLoading = true;
    const token = localStorage.getItem('tokenCare');
    
    const headers: HeadersInit = new Headers({
      Authorization: token
    });

    const config: RequestInit = {
      method: 'POST',
      headers,
      body: data
    };

    const URL = `${this.rootUrl}/v1.2/api/bucket/add-icon-supervisor`;
    //https://dev-backend.rhodium.health/v1.2/api/bucket/add-icon-supervisor
    const response = await fetch(URL, { method: 'POST', headers: headers, body: data });
    //const response = await fetch(URL, config);
    const body = await response.json();
    if (body.status === 'ok') {
      this.imageURLprofile = body.results[0];
      this.passDataService.changeMessage('profileImageChanged');
    }
    this.isLoading = false;
  }

  public handleClickCopy() {
    const id = this.userId;
    navigator.clipboard.writeText(id);
    this.snackbarService.openSnackBar('Se ha copiado el ID con éxito', SNACKBAR_RESULT.success);
  }

  public async getProfileImage(){
    try {
      const response: any = await this.profileService.getProfileImage();

      if(response.status === 'ok' && response.results){
        this.currentPic = response.results;
      }else {
        this.currentPic = 'assets/images/user-none.png';
      }
    } catch (error) {
      
    }
  }

}
