<div *ngIf="isLoading" class="tb-spinner">
    <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
</div>
<section class="container tb-row">
    <app-profile-photo *ngIf="isMobile"></app-profile-photo>
    <mat-tab-group mat-align-tabs="start" animationDuration="0ms">
        <mat-tab label="Personal">
            <div class="item">
                <mat-card class="card mat-elevation-z0">
                    <mat-card-header>
                        <mat-card-title>Informacion Personal</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <form class="width-100" [formGroup]="profileForm">
                            <mat-form-field color="accent" class="width-100" appearance="outline">
                                <mat-label>
                                    ID
                                </mat-label>
                                <input type="text" formControlName="Id" matInput required />

                            </mat-form-field>
                            <mat-form-field color="accent" class="width-100" appearance="outline">
                                <mat-label>
                                    Nombre
                                </mat-label>
                                <input type="email" formControlName="name" matInput required />
                                <mat-icon (click)="openDialog()" class="cursor-pointer" matSuffix>create</mat-icon>

                            </mat-form-field>
                            <mat-form-field color="accent" class="width-100" appearance="outline">
                                <mat-label>
                                    Correo
                                </mat-label>
                                <input type="email" formControlName="email" matInput required />

                            </mat-form-field>
                            <mat-form-field color="accent" class="width-100" appearance="outline">
                                <mat-label>
                                    Telefono
                                </mat-label>
                                <input type="email" formControlName="phone" matInput required />

                            </mat-form-field>
                            <mat-form-field color="accent" class="width-100" appearance="outline">
                                <mat-label>
                                    Perfil
                                </mat-label>
                                <input type="email" formControlName="profile" matInput required />

                            </mat-form-field>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-tab>
    </mat-tab-group>
</section>