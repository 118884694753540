<div class="statics">
    <span class="title-card color-black font-weigth-500">
        Estadísticas Generales
    </span>

    <div class="container">
        <div class="section" [ngClass]="{'cursor-pointer': statistics.function}" *ngFor="let statistics of dashboardStatistics" (click)="executeFunction(statistics)">
            <div class="tb-margin-tpa tb-row tb-align-center tb-justify-center">
                <mat-icon class="tb-icon" svgIcon="{{statistics.icon}}"></mat-icon>
                <span class="tb-title color-secondary" *ngIf="statistics.id != 'google_play' && statistics.id != 'appstore'">
                    {{statistics.value ? statistics.value : '0'}}
                </span>
            </div>
            <div class="tb-column">
                <span class="tb-description color-secondary tb-text-center">
                    {{statistics.title}}
                </span>
                <span class="tb-description color-primary">
                    {{statistics.subtitle}}
                </span>
            </div>
        </div>

    </div>
    <div class="no-sections" *ngIf="dashboardStatistics.length < 1">
        <mat-spinner diameter="45"></mat-spinner>
    </div>    
</div>