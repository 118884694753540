import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup,  Validators, FormBuilder, FormControl } from '@angular/forms';
import { SupervisorsService } from 'src/app/pages/supervisors/supervisors.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { SNACKBAR_RESULT } from 'src/app/shared/data/data';



@Component({
  selector: 'app-edit-supervisors',
  templateUrl: './edit-supervisors.component.html',
  styleUrls: ['./edit-supervisors.component.scss']
})

export class EditSupervisorsComponent implements OnInit {
  public isLoading = false;
  public editSupervisorsForm: FormGroup;
  public profileValue = [
    {value: 'admin', text: 'Administrador'},
    {value: 'RH', text: 'RH'},
    {value: 'observers', text: 'Observadores'},
  ];

  public statusValue = [
    {value: 'active', text: 'Habilitado'},
    {value: 'inactive', text: 'Inhabilitado'},
  ]
  

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public form: FormBuilder,
    public supervisors: SupervisorsService,
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
  ) {
    this.data = data.data;
  }

  ngOnInit(): void {

    this.editSupervisorsForm = this.form.group({
      name: [this.data.name, [
        Validators.required,
        Validators.minLength(3),
      ]],
      lastName: [this.data.lastName, [
        Validators.required,
        Validators.minLength(3),
      ]],
      profile: [this.data.profile, [
        Validators.required,
      ]],
      status: [this.data.status,  [
        Validators.required,
      ]],
    });
  }

  public closetEdit() {
    this.dialog.closeAll();
  }


  public async editSupervisor(){
    try {
      this.isLoading = true;
      const { name, lastName, profile, status } = this.editSupervisorsForm.value;
      const response: any = this.supervisors.editSupervisors(this.data.uuid, name, lastName, profile, status);

      if(response){
        this.snackbarService.openSnackBar('Se ha guardado correctamente.',SNACKBAR_RESULT.success);
        this.closetEdit();
      }
      this.isLoading = false;
      
    } catch (error) {
      this.isLoading = false;
      this.snackbarService.openSnackBar('Hubo un problema al guardar los cambios, intenta de nuevo más tarde.',SNACKBAR_RESULT.fail);
      
    }
  }

}
