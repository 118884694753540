import { Component, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { Router, NavigationEnd } from '@angular/router';
import { PassDataService } from 'src/app/shared/services/pass-data.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  public subs = new SubSink();

  public current = '/home';

  public sidenavButtons = [
    /* {
      icon: 'icon-home',
      title: 'Dashboard',
      route: '/home'
    },
    {
      icon: 'icon-beneficiaries',
      title: 'Beneficiarios',
      route: '/beneficiaries'
    } */
  ];


  constructor(
    private router: Router,
    private passDataService: PassDataService
  ) {
    this.subs.add(
      this.passDataService.currentMessage.subscribe(message => {
        if (message === 'selectOrganization') {
          this.getOrganizationData();
        }
      }),
      router.events.subscribe((e: any) => {
        if (e instanceof NavigationEnd) {
          this.current = e.url;

        }
      })

    );
  }

  ngOnInit(): void {
    this.isLoggerd();
    this.getRouterCurrent();
    this.getOrganizationData();
  }

  getRouterCurrent() {
    this.current = this.router.url;
  }

  getOrganizationData() {
    const organization = JSON.parse(localStorage.getItem('organizationData'));
    if (organization.profile === 'admin') {
      if (!this.sidenavButtons.find(value => value.route === '/supervisors')) {
        this.sidenavButtons.push({
          icon: 'icon-supervisors',
          title: 'Supervisores',
          route: '/supervisors'
        });
      }
    }
  }

  onRedirecto(button) {
    if (button.route === '/home' ||
      button.route === '/supervisors' ||
      button.route === '/beneficiaries'
    ) {
      this.router.navigate([button.route]);
    }
  }

  routeListSidenav() {
    localStorage.getItem('organizationData');
  }

  isLoggerd() {
    const token = localStorage.getItem('tokenCare');
    if (token) {
      if (!this.sidenavButtons.find(value => value.route === '/home')) {
        this.sidenavButtons.unshift({
          icon: 'icon-home',
          title: 'Dashboard',
          route: '/home'
        });
      }
      if (!this.sidenavButtons.find(value => value.route === '/beneficiaries')) {
        this.sidenavButtons.push({
          icon: 'icon-beneficiaries',
          title: 'Beneficiarios',
          route: '/beneficiaries'
        });
      }
    }
  }

}
