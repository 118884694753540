import { HttpClient, HttpHeaders, HttpHeaderResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupervisorsService {
  private URL: string = environment.appApiUrl;

  constructor(
    private http: HttpClient
  ) { }

  getSupervisors(page, size) {
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const URL = `${this.URL}/supervisor-teeb-care?page=${page}&size=${size}`;

    const headers = {
      Authorization: token,
      organization: organization
    };

    return this.http.get(URL, { headers }).toPromise();
  }

  public editSupervisors(uuid: string, name: string, lastName: string, profile, status: string) {
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const URL = `${this.URL}/supervisor-teeb-care/${uuid}`;

    const headers = {
      Authorization: token,
      organization: organization
    };

    const body = {
      name,
      last_name: lastName,
      profile,
      status
    };

    return this.http.put(URL, body, { headers }).toPromise();
  }

  public deleteSupervisors(uuid:string){
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const URL = `${this.URL}/supervisor-teeb-care/organization/${uuid}`;

    const headers = {
      Authorization: token,
      organization: organization
    };

    return this.http.delete(URL, { headers }).toPromise();
  }

  public addSupervisors(email, profile, url){
    const token  = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const URL = `${this.URL}/invitation-supervisor-teeb-care`;

    const headers = {
      Authorization: token,
      organization: organization
    };

    const body = {
      email,
      profile,
      domain: url
    }

    return this.http.post(URL, body, { headers }).toPromise();
  }

  public getInvitations(page, size){
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const URL = `${this.URL}/invitation-supervisor-teeb-care?page=${page}&size=${size}`;

    const headers = {
      Authorization: token,
      organization: organization
    };

    return this.http.get(URL, { headers }).toPromise();
  }

  public deleteInvitations(uuid:string){
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const URL = `${this.URL}/invitation-supervisor-teeb-care/${uuid}`;

    const headers = {
      Authorization: token,
      organization: organization
    };

    return this.http.delete(URL, { headers }).toPromise();
  }

}
