<div *ngIf="isLoading" class="tb-spinner">
    <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
</div>
<h2 mat-dialog-title>Importar Beneficirios pendientes</h2>
<section mat-dialog-content>
    <mat-stepper>
        <mat-step>
            <ng-template matStepLabel>Consideración</ng-template>
            <h3>Para subir Colaboradores por CSV tomar en cuenta lo siguiente:</h3>
            
            <span>
                * El formato de CSV debe cumplir <b>mandatoriamente</b> CURP, Nombre(s), Apellido(s) y RFC.
                <br/>
                * El formato de CSV <b>opcionalmente</b> puede tener Correo , Teléfono, Certificado y URL del Reporte Médico.
            </span>
            <br/>
            <button class="buttonAceppt margin-top-15" mat-raised-button matStepperNext >
                Continuar
            </button>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Descargar plantilla</ng-template>
            <span>
                Puedes tomar el siguiente formato como base:
            </span>
            <div class="contentImg">
                <img src="assets/files/collaboratorsExample.png"/>

            </div>
            <div class="content-button-dowload">

                <button mat-raised-button class="buttonAceppt" (click)="downloadCSV()">Descargar Formato</button>
            </div>
            
            <br/>
            <button class="buttonCancel margin-top-15" mat-raised-button matStepperPrevious>
                Volver
            </button>
            <button class="buttonAceppt margin-top-15" mat-raised-button matStepperNext >
                Continuar
            </button>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Subir archivo</ng-template>
            <span>
                Seleccione el archivo CSV y haga clic en el botón de subir
            </span>
            <div class="contentButton">
                <button class="buttonAceppt" mat-raised-button (click)="fileInput.click()">
                    Seleccionar Archivo CSV
                </button>
                <input hidden type="file" #fileInput accept=".csv"
                        (change)="showPreview($event)" />
            </div>
            <div *ngIf="nameFile" class="contentArchivo">
                <span>
                    Archivo seleccionado
                </span>
                <span>
                    {{nameFile}}
                </span>
            </div>
            <br/>
            <button class="buttonCancel" mat-raised-button matStepperPrevious>
                Volver
            </button>
            <button class="buttonAceppt" mat-raised-button matStepperNext (click)="uploadFile()">
                Subir
            </button>
        </mat-step>

    </mat-stepper>

</section>
