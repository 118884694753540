import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { PdfPreviewComponent } from 'src/app/dialogs/pdf-preview/pdf-preview.component';
import { BeneficiariesService } from 'src/app/pages/beneficiaries/beneficiaries.service';

@Component({
  selector: 'app-individual-health-traffic-light',
  templateUrl: './individual-health-traffic-light.component.html',
  styleUrls: ['./individual-health-traffic-light.component.scss']
})
export class IndividualHealthTrafficLightComponent implements OnInit {
  private appApiUrlHealth: string =   environment.appApiUrlHealth;
  @ViewChild('ContentIframeIn') ContentIframe: ElementRef;
  public idUser: string;
  public isLoading: boolean = true;
  public dialogRef: any;
  public urlResultRisk: string;
  public existsReport: boolean = false;
  public existQsessionTeeb: boolean = false;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private dialog: MatDialog,
    private beneficiariesService: BeneficiariesService
  ) { }


  ngOnInit(): void {
    this.idUser = this.router.url.split('/')[3];
    this.getUrlReport();
    this.getCheckQsessionTeeb();
  }

  ngAfterViewInit(): void {
    //this.showPage();
  }

  public showPage() {
    this.isLoading = true;
    try {
      const idOrganization = localStorage.getItem('organization');
      const asIframe = this.ContentIframe.nativeElement;
      const url = `${this.appApiUrlHealth}q/qsession-teeb/${this.idUser}/${idOrganization}/?iframe=True`;
      this.renderer.setAttribute(asIframe, 'src', url);
      asIframe.onload = () => {
        this.isLoading = false;
      }
    } catch (error) {
      this.isLoading = false;
    }


  }

  public dialogPreviewPDF(){
    window.open(this.urlResultRisk, '_blank');
    /* this.dialogRef = this.dialog.open(PdfPreviewComponent, {
      data: this.urlResultRisk,
    }); */
  }

  public async getUrlReport(){
    const beneficiarieSelect = JSON.parse(localStorage.getItem('beneficiarieSelect'));
    try {
      if(beneficiarieSelect.idCollaborator){
        const response: any = await this.beneficiariesService.pendingCollaboratorTeebCare(beneficiarieSelect.idCollaborator);
        
        const { collaborator }= response.results[0];
        if(response.status === 'ok' && collaborator){
          if(collaborator.result_risk){
            this.urlResultRisk = collaborator.result_risk;
            this.existsReport = true;
          }else {
            this.urlResultRisk = '';
            this.existsReport = false;
          }
        }
        
      }
    } catch (error) {
      
    }

  }

  public async getCheckQsessionTeeb() {
    try {
      const response: any = await this.beneficiariesService.getCheckQsessionTeeb(this.idUser);
      if(response.result){
        this.existQsessionTeeb = true;
        setTimeout(() => {
          this.showPage();
        }, 300);
      }else {
        this.existQsessionTeeb = false;
      }
      this.isLoading = false;
    } catch (error) {
      if(error.error){
        if(!error.error.result) {
          this.existQsessionTeeb = false;
          this.isLoading = false;
        }
        this.isLoading = false;

      }
    }
  }

}
