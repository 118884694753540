import { Component, OnInit, Optional, Inject, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss']
})
export class PdfPreviewComponent implements OnInit {
  @ViewChild('ContentIframe') ContentIframe: ElementRef;
  public urlPDF: string = '';
  public isLoading: boolean = false;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.urlPDF = this.data;
  }

  ngAfterViewInit(): void {
    //this.showPage();
  }

  public showPage(){
    this.isLoading = true;
    try {
      const asIframe = this.ContentIframe.nativeElement;
      const url = this.urlPDF;
      this.renderer.setAttribute(asIframe, 'src', url);
      asIframe.onload = () => {
        this.isLoading = false;
      }
    } catch (error) {
      
    }
  }

  public closedDialog() {
    this.dialog.closeAll();
  }

}
