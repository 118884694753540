import { Component, OnInit } from '@angular/core';
import { delay } from 'rxjs';
import { SubSink } from 'subsink';
import { BeneficiariesService } from 'src/app/pages/beneficiaries/beneficiaries.service';
import { PassDataService } from 'src/app/shared/services/pass-data.service';
import { Router } from '@angular/router';
import { ComingSoonMessageComponent } from 'src/app/dialogs/coming-soon-message/coming-soon-message.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-statistics-counters',
  templateUrl: './statistics-counters.component.html',
  styleUrls: ['./statistics-counters.component.scss']
})
export class StatisticsCountersComponent implements OnInit {
  public activeCollaborator:string;
  public appoimentGeneral:string;
  public appoimentNutritionist:string;
  public appoimentPsychology:string;
  public inactiveCollaborator:string;
  public totalAppoiment:string;
  public smartCheckupTotal:string;
  public consultations: any;
  public emergencyCalls: any;
  public subs = new SubSink();
  public total: any;
  public dashboardStatistics = [];
  public statistics = [];


  constructor(
    private beneficiariesService: BeneficiariesService,
    private passDataService: PassDataService,
    private router: Router,
    private dialog: MatDialog
  ) { 
    this.subs.add(
      this.passDataService.currentMessage.subscribe( message => {
        if(message === 'selectOrganization'){
          this.getStatistics();
          this.supervisorTeebCareDasboard();
          this.smartCheckup();
          this.sumQueries();
        }
      })
    )
  }

  ngOnInit(): void {
    this.getStatistics();
    this.supervisorTeebCareDasboard();
    this.smartCheckup();
    this.sumQueries();
  }

  public async supervisorTeebCareDasboard(){
    try {
      const res: any = await this.beneficiariesService.supervisorTeebCareDasboard();
      console.log("supervisorTeebCareDasboard", res);
      if(res.status === 'ok'){
        /* const { 
          active_collaborator, 
          inactive_collaborator,
          emergency_calls
        } = res.results;
        this.activeCollaborator = active_collaborator;
        this.inactiveCollaborator = inactive_collaborator;
        this.emergencyCalls = emergency_calls; */
        this.assigningValuesCards(res.results);
      }
    } catch (error) {
    }
  }


  public async smartCheckup(){
    try {
      const response: any = await this.beneficiariesService.smartCheckup();
      console.log("smartCheckup", response);
      this.smartCheckupTotal = response.closed;
      this.assigningValuesCards(response);
    } catch (error) {
    }
  }

  public async sumQueries(){
    try {
      const res:  any = await this.beneficiariesService.sumQueries();
      console.log("sumQueries", res);
      this.assigningValuesCards(res?.consultations?.type);
      this.consultations = res?.consultations?.type;
      this.totalAppoiment = res?.consultations?.count;

    } catch (error) {
    }
  }

  public addressingBeneficiaries(filter: string){
    localStorage.setItem('filterBeneficiaries', filter);
    this.router.navigate(['/beneficiaries']);
  }

  public googleRedirect(){
    window.open('https://www.google.com/', '_blank');
  }

  public iosRedirect(){
    window.open('https://www.apple.com/lae/ios/app-store/', '_blank');
  }

  public openDilaogSoon(){
    this.dialog.open(ComingSoonMessageComponent, {
      width: '400px'
    });
  }

  public executeFunction(item:any){

    if('addressingBeneficiariesRedeemed' === item.function) {
      this.addressingBeneficiaries('redeemed');
    } else if ('addressingBeneficiariesPending' === item.function) {
      this.addressingBeneficiaries('pending');
    } else if ('openDilaogSoon' === item.function) {
      this.openDilaogSoon();
    }
  }

  public assigningValuesCards(ObjectValue){
    if(ObjectValue){
      const arrayValues = Object.keys(ObjectValue);
      for(let i = 0; i < this.statistics.length; i++){
        for(let j = 0; j < arrayValues.length; j++){
          const element = arrayValues[j];
          if(this.statistics[i].id === element){
            this.statistics[i].value = ObjectValue[element];
          }
        }
      }

      this.dashboardStatistics = this.statistics;
    }
  }

  public getStatistics(){
    const organizationData = JSON.parse(localStorage.getItem('organizationData'));
    this.statistics = organizationData.organization.web_config?.statistics.dashboardStatistics;
  }

}
