import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileService } from 'src/app/pages/profile/profile.service';

@Component({
  selector: 'app-organization-select',
  templateUrl: './organization-select.component.html',
  styleUrls: ['./organization-select.component.scss']
})
export class OrganizationSelectComponent implements OnInit {

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private profile: ProfileService
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    
  }

  public selectOrganization(organization) {
    localStorage.setItem('organization', organization);
    this.dialog.closeAll();
  }

}
