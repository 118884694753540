<div *ngIf="isLoading" class="tb-spinner">
    <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
</div>
<div class="content-button-close cursor-pointer" (click)="closedDialog()">
    X
</div>
<!-- <embed [src]="urlPDF" type="application/pdf" class="content-PDF"/> -->
<!-- <iframe src="urlPDF">

</iframe> -->

<object [data]="urlPDF" height="90%" width="100%"></object>