<div class="alerContent {{ type }}">
    <div>
        <div class="alertIconContent">
            <mat-icon>report_problem</mat-icon>

        </div>
        <div class="alertTextContent">
            {{ message }}
        </div>

    </div>
</div>