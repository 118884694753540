import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BeneficiariesService } from 'src/app/pages/beneficiaries/beneficiaries.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { SNACKBAR_RESULT } from 'src/app/shared/data/data';


@Component({
  selector: 'app-delete-beneficiaries',
  templateUrl: './delete-beneficiaries.component.html',
  styleUrls: ['./delete-beneficiaries.component.scss']
})
export class DeleteBeneficiariesComponent implements OnInit {
  public error: boolean = false;
  public messageError: string = '';
  public isLoading: boolean = false;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private beneficiariesService: BeneficiariesService,
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
  ) { 
    this.data = data; 
  }

  ngOnInit(): void {
  }


  public async deleteBeneficiaries(){
    try {
      this.isLoading = true;
      const response = await this.beneficiariesService.deleteBeneficiaries(this.data.id);
      
      if(response === null){
        this.snackbarService.openSnackBar('Se ha eliminado correctamente.',SNACKBAR_RESULT.success);
        this.dialog.closeAll();
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.snackbarService.openSnackBar('Hubo un problema al eliminar, intenta de nuevo más tarde.',SNACKBAR_RESULT.fail);
      
    }
  }

}
