import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-form',
  templateUrl: './alert-form.component.html',
  styleUrls: ['./alert-form.component.scss']
})
export class AlertFormComponent implements OnInit {
  @Input() message: string;
  @Input() type: 'success' | 'error';

  constructor() { }

  ngOnInit(): void {
  }

}
