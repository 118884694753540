import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertFormComponent } from './alert-form/alert-form.component';
import { MaterialModule } from '../material.module';
import { StatisticsCountersComponent } from './statistics-counters/statistics-counters.component';
import { StaticInfoComponent } from './static-info/static-info.component';
import { CardMembershipComponent } from './card-membership/card-membership.component';
import { HealthTrafficLightHomeComponent } from './health-traffic-light-home/health-traffic-light-home.component';
import { StaticCountersProfileComponent } from './static-counters-profile/static-counters-profile.component';
import { IndividualHealthTrafficLightComponent } from './individual-health-traffic-light/individual-health-traffic-light.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [
    AlertFormComponent,
    StatisticsCountersComponent,
    StaticInfoComponent,
    CardMembershipComponent,
    HealthTrafficLightHomeComponent,
    StaticCountersProfileComponent,
    IndividualHealthTrafficLightComponent,
    SnackbarComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule
  ],
  exports: [
    AlertFormComponent,
    StatisticsCountersComponent,
    StaticInfoComponent,
    CardMembershipComponent,
    HealthTrafficLightHomeComponent,
    StaticCountersProfileComponent,
    IndividualHealthTrafficLightComponent
  ]
})
export class ComponentsModule { }
