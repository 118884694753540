<div class="content">
    <div class="container-info tb-column">
        <!-- para mostrar la otra cara agregar la funcion a #card -->
        <!-- <div class="arrow-1">
            <img src="assets/images/arrow1.png"/>
        </div> -->
        <!-- (click)="showInFront()" -->
        <div class="card" id="card" #card >
            <div  class="in-front tb-column position-card">
                <div class="tb-row">
                    <!-- <span class="title-1 tb-white">
                        RHODIUM
                    </span> -->
                    <img class="imgCard" src="assets/images/logoCard.png"/>
                    <!-- <span class="spacer"></span>
                    <img src="assets/images/card-emprendamos2.png"/> -->
                </div>
                <div class="tb-row">
                    <span class="tb-white title-2">{{organizationName}}</span>
                </div>
                <div class="tb-row title-3">
                    <div class="tb-column tb-white">
                        <span>No. Contrato:</span>
                        <span>{{contractNumber}}</span>
                    </div>
                    <!-- <span class="spacer"></span>
                    <div class="tb-column tb-white">
                        <span>No. Póliza:</span>
                        <span>{{insuranceNumber}}</span>
                    </div> -->

                </div>
                <div class="tb-row stretch-flex-end">
                    <img class="logo-footer" style="margin-right: 10px;" src="assets/images/logo-desclub.png"/>
                    <img class="logo-footer" style="margin-right: 10px;" src="assets/images/logo-allianz.png"/>
                    <img class="logo-footer" src="assets/images/logo-sura.png"/>
                </div>
            </div>
            <div class="back tb-column">
                <div class="tb-column">
                    <div class="tb-row membership">
                        <span class="title-3 tb-white">
                            Tipo de Membresia: 
                        </span>
                        <span class="title-4 tb-white data">
                            FullCare
                        </span>
                    </div>
                    <div class="tb-row color-bb">
                        <div>
                            <span class="title-3 tb-white">
                                Cantidad: 
                            </span>
                            <span class="title-4 tb-white data">
                                27
                            </span>
                        </div>
                        <div class="margin-lf20">
                            <span class="title-3 tb-white">
                                Tarifa:  
                            </span>
                            <span class="title-4 tb-white data">
                                $150
                            </span>
                        </div>
                    </div>
                    <div class="tb-row membership">
                        <span class="title-3 tb-white">
                            Tipo de Membresia: 
                        </span>
                        <span class="title-4 tb-white data">
                            Preventive Care
                        </span>
                    </div>
                    <div class="tb-row color-bb">
                        <div>
                            <span class="title-3 tb-white">
                                Cantidad: 
                            </span>
                            <span class="title-4 tb-white data">
                                27
                            </span>
                        </div>
                        <div class="margin-lf20">
                            <span class="title-3 tb-white">
                                Tarifa:  
                            </span>
                            <span class="title-4 tb-white data">
                                $150
                            </span>
                        </div>
                    </div>
                    <div class="tb-column  tb-white">
                        <div class="tb-row bill">
                            <span class="title-3">
                                Valor de Factura + IVA : 
                            </span>
                            <span class="data title-4">
                                $15,525
                            </span>
                        </div>
                        <div class="tb-row bill">
                            <span class="title-3">
                                Fecha de Corte: 
                            </span>
                            <span class="data title-4">
                                Octubre 25 2023
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="arrow-2">
            <img src="assets/images/arrow2.png"/>
        </div> -->
    </div>
</div>