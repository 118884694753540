<ng-template #outlet>
    <router-outlet></router-outlet>
</ng-template>

<div *ngIf="displayLayout; else noLayout" class="appContainer">
    <app-layout>
        <ng-container *ngTemplateOutlet="outlet"></ng-container>
    </app-layout>
</div>

<ng-template #noLayout class="appContainer">
    <ng-container *ngTemplateOutlet="outlet"></ng-container>
</ng-template>