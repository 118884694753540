<ng-container>
    <div class="Sidenav">
        <div id="SidenavContainerToggle">
            <div id="iconsContainerToggle">
                <div class="sidenavElement" *ngFor=" let button of sidenavButtons" (click)="onRedirecto(button)">
                    <div class="item">
                        <mat-icon class="icon-side" [svgIcon]="button.icon" [ngClass]="{'icon-color-accent': current.includes(button.route), 'icon-color-gray': !current.includes(button.route)}">
                        </mat-icon>
                        <span class="title" [ngClass]="{ 'color-title-active': current.includes(button.route), 'color-title-inactive': !current.includes(button.route) }">
                            {{button.title}}
                        </span>
                    </div>
                    <!-- <div>
                    </div> -->
                </div>
            </div>

        </div>
    </div>

</ng-container>