<span class="titleCard">
    Estadisticas Generales
</span>
<div class="container">
    <div class="section" [ngClass]="{'cursor-pointer': statistics.function}" *ngFor="let statistics of digitalProfileStatistics" (click)="executeFunction(statistics)">
        <div class="tb-margin-tpa">
            <mat-icon class="tb-icon" svgIcon="{{statistics.icon}}"></mat-icon>
            <span class="tb-text-32 color-secondary tb-text-bold">{{statistics.value ? statistics.value : '0'}}</span>
        </div>
        <div class="tb-column">
            <span class="tb-title color-secondary">
                {{statistics.title}}
            </span>
            <span class="tb-title color-primary">
                {{statistics.subtitle}}
            </span>
        </div>
    </div>
</div>
<div class="no-sections" *ngIf="digitalProfileStatistics.length < 1">
    <mat-spinner diameter="45"></mat-spinner>
</div>