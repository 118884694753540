import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BeneficiariesService } from 'src/app/pages/beneficiaries/beneficiaries.service';


@Component({
  selector: 'app-add-csv-beneficiaries',
  templateUrl: './add-csv-beneficiaries.component.html',
  styleUrls: ['./add-csv-beneficiaries.component.scss']
})
export class AddCsvBeneficiariesComponent implements OnInit {
  public isLoading: boolean = false;
  public nameFile: string = '';
  public file: any;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public beneficiaries: BeneficiariesService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  showPreview(event){
    const file = (event.target as HTMLInputElement).files[0];
    this.file = file;
    this.nameFile = file.name;
  }
  public async uploadFile(){

    if(this.file){
      this.isLoading = true;
      try {
        const response: any = await this.beneficiaries.uploadFileCSV(this.file);
        if(response.status === 'Ok'){
          this.isLoading = false;
          this.dialog.closeAll();
        }
      } catch (error) {
        this.isLoading = false;
        this.dialog.closeAll();
      }

    }

  }

  public downloadCSV() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../assets/files/MCBrokers Usuarios.csv');
    link.setAttribute('download', `MCBrokers Usuarios.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

}
