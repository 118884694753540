<div class="statics">
    <div class="container-info tb-row">
        <div class="tb-data">
            <div class="content-info tb-align-center tb-justify-center width-100">
                <span class="color-primary title spacer">¿Necesitas Ayuda?</span>
                <div class="tb-row spacer">
                    <mat-icon class="tb-icon" svgIcon="icon-email"></mat-icon>
                    <span class="text-info tb-grey align-items-center">
                        info@rhodium.ooo
                    </span>
                </div>
                <!-- <div class="tb-row spacer">
                    <mat-icon class="tb-icon" svgIcon="icon-whatsApp"></mat-icon>
                    <span class="text-info tb-grey align-items-center">
                        444 195 1136
                    </span>
                </div> -->
            </div>
        </div>
    </div>
</div>