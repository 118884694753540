import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACKBAR_CONFIG } from '../data/data';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  public style: string;
  constructor(private snackBar: MatSnackBar) { }

  public openSnackBar(text, type) {
    switch (type){
      case 1:
        this.style = 'nm-blue-snackbar';
        break;
      case 2:
        this.style = 'nm-red-snackbar';
        break;
      default:
        this.style = 'nm-red-snackbar';
    }
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: SNACKBAR_CONFIG.duration,
      horizontalPosition: SNACKBAR_CONFIG.horizontalPosition,
      verticalPosition: SNACKBAR_CONFIG.verticalPosition,
      panelClass: [this.style],
      data: text
    });
  }
}
