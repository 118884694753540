import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/pages/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private URL: string = environment.appApiUrl;
  private rootUrl: string = environment.rootUrl;
  public appApiUrlHealth: string = environment.appApiUrlHealth;
  public wsURL: string = environment.wsURL;
  public token: string = '';

  constructor(
    private http: HttpClient,
    public auth: AuthService
  ) { }

  public getProfile() {
    this.token = localStorage.getItem('tokenCare') || '';
    const headers = {
      Authorization: `${this.token}`
    };

    const URL = `${this.URL}/supervisor/get-info`;

    return this.http.get(URL,{ headers}).toPromise();
  }

  public editProfileSupervisor(uuid: string, name: string , lastName: string) {
    this.token = localStorage.getItem('tokenCare') || '';
    let organization =  localStorage.getItem('organization') || '';
    
    const headers = {
      Authorization: `${this.token}`,
      organization: organization
    };

    const body = {
      name,
      last_name: lastName
    };

    const URL = `${this.URL}/supervisor-teeb-care/${uuid}`;

    return this.http.put(URL, body,{ headers}).toPromise();
  }

  public getPicture(organitationUUID){
    const token = localStorage.getItem('tokenCare') || '';
    const headers = {
      Authorization: `${token}`
    };
    const URL = `${this.rootUrl}/v1.2/api/bucket/getFileOrganization/${organitationUUID}`;
    return this.http.get(URL, { headers }).toPromise();
  }

  public getPictureUser(uuid, fileType: string){
    const token = localStorage.getItem('tokenCare') || '';
    const headers = {
      Authorization: `${token}`
    };
    const URL = `${this.rootUrl}/v1.2/api/bucket/getFiles/supervisors/${uuid}/${fileType}`;
    return this.http.get(URL, { headers }).toPromise();

  }

  public sumQueries(organizationUUID) {
    this.token = localStorage.getItem('tokenCare') || '';
    const headers = {
      Authorization: `${this.token}`
    };

    const URL = `${this.wsURL}/api/organization/info/${organizationUUID}/`;
    //https://dev-ws.rhodium.health/api/organization/info/4b3b3512-4132-46ef-9527-dd241bef523b/

    return this.http.get(URL,{ headers}).toPromise();
  }

  public getProfileImage(){
    this.token = localStorage.getItem('tokenCare') || '';
    const headers = {
      Authorization: `${this.token}`
    };

    const URL = `${this.rootUrl}/v1.2/api/bucket/get-icon-supervisor`;
    //https://dev-backend.rhodium.health/v1.2/api/bucket/get-icon-supervisor

    return this.http.post(URL,{},{ headers}).toPromise();
  }

  
}
