import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { SubSink } from 'subsink';
import { PassDataService } from 'src/app/shared/services/pass-data.service';


@Component({
  selector: 'app-card-membership',
  templateUrl: './card-membership.component.html',
  styleUrls: ['./card-membership.component.scss']
})
export class CardMembershipComponent implements OnInit {
  @ViewChild('card') card: ElementRef;
  public insuranceNumber: string = '';
  public organizationData: any;
  public contractNumber: string = '';
  private subs = new SubSink();
  public organizationName = '';

  constructor(
    private renderer: Renderer2,
    private passDataService: PassDataService,
  ) { 
    this.subs.add(
      this.passDataService.currentMessage.subscribe( message => {
        if(message === 'selectOrganization'){
          this.insuranceNum();
        }
      })
    )
  }

  ngOnInit(): void {
    this.insuranceNum();
  }

  public showInFront(){
    const asCard = this.card.nativeElement;
    if(asCard.classList.contains('active')){
      this.renderer.removeClass(asCard, 'active');
    } else {
      this.renderer.addClass(asCard, 'active');
    }
  }

  public insuranceNum(){
    this.organizationData = JSON.parse(localStorage.getItem('organizationData'));
    this.organizationName = this.organizationData.organization.name ? this.organizationData.organization.name : '';
    this.insuranceNumber = this.organizationData.organization.insurance_number;
    this.contractNumber = this.organizationData.organization.contract_number ? this.organizationData.organization.contract_number : '#####';

  }

}
