import { Component, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup,  Validators, FormBuilder, FormControl } from '@angular/forms';
import { SupervisorsService } from 'src/app/pages/supervisors/supervisors.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { SNACKBAR_RESULT } from 'src/app/shared/data/data';

@Component({
  selector: 'app-add-supervisors',
  templateUrl: './add-supervisors.component.html',
  styleUrls: ['./add-supervisors.component.scss']
})
export class AddSupervisorsComponent implements OnInit {
  public addSupervisorsForm: FormGroup;
  public isLoading = false;
  public urlPrincipal;

  public profileValue = [
    {value: 'admin', text: 'Administrador'},
    {value: 'RH', text: 'RH'},
    {value: 'observers', text: 'Observadores'},
  ];

  constructor(
    public form: FormBuilder,
    public dialog: MatDialog,
    public supervisorsService: SupervisorsService,
    private snackbarService: SnackbarService,
  ) { 
    let url = document.location.href.split('/');
    this.urlPrincipal = url[0] + '//' + url[2] + '/';
  }

  ngOnInit(): void {
    this.addSupervisorsForm = this.form.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      profile: ['', [
        Validators.required,
      ]]
    })
  }

  public async addSupervisor(){
    if (this.addSupervisorsForm.valid) {
      this.isLoading = true;
      try {

        const { email, profile } = this.addSupervisorsForm.value;
        
        const response: any = await this.supervisorsService.addSupervisors(email, profile, this.urlPrincipal);
        if(response.status === 'ok'){
          this.snackbarService.openSnackBar('Se ha guardado correctamente.',SNACKBAR_RESULT.success);
          this.dialog.closeAll();
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.snackbarService.openSnackBar('Hubo un problema al guardar los cambios, intenta de nuevo más tarde.',SNACKBAR_RESULT.fail);
      }
    }
    
  }

}
