<div *ngIf="isLoading" class="tb-spinner">
    <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
</div>
<div class="title" mat-dialog-title> Eliminar Invitación</div>

<div mat-dialog-content>
    <div class="description">
        <span>
            Después de eliminar una invitación de supervisor, esta se borra de manera permanente. Esta acción no se
            puede deshacer.
        </span>
    </div>
    <div *ngIf="error" class="error">
        <span>
            {{messageError}}
        </span>
    </div>
    <div class="tb-row tb-justify-sb">
        <button class="buttonCancel" mat-raised-button mat-dialog-close>
            Cancelar
        </button>
        <button class="buttonAceppt" mat-raised-button (click)="deleteInvitation()">
            Eliminar
        </button>

    </div>

</div>