import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditSupervisorsComponent } from './edit-supervisors/edit-supervisors.component';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeleteSupervisorsComponent } from './delete-supervisors/delete-supervisors.component';
import { AddSupervisorsComponent } from './add-supervisors/add-supervisors.component';
import { DeleteInvitationComponent } from './delete-invitation/delete-invitation.component';
import { EditBeneficiariesComponent } from './edit-beneficiaries/edit-beneficiaries.component';
import { DeleteBeneficiariesComponent } from './delete-beneficiaries/delete-beneficiaries.component';
import { AddCsvBeneficiariesComponent } from './add-csv-beneficiaries/add-csv-beneficiaries.component';
import { PdfPreviewComponent } from './pdf-preview/pdf-preview.component';
import { NoOrganizationsComponent } from './no-organizations/no-organizations.component';
import { ComingSoonMessageComponent } from './coming-soon-message/coming-soon-message.component';



@NgModule({
  declarations: [
    EditSupervisorsComponent,
    DeleteSupervisorsComponent,
    AddSupervisorsComponent,
    DeleteInvitationComponent,
    EditBeneficiariesComponent,
    DeleteBeneficiariesComponent,
    AddCsvBeneficiariesComponent,
    PdfPreviewComponent,
    NoOrganizationsComponent,
    ComingSoonMessageComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class DialogsModule { }
