import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './pages/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { ComponentsModule } from './components/components.module';
import { LayoutModule } from './layout/layout.module';
import { ProfileComponent } from './pages/profile/profile.component';
import { EditProfileSupervisorComponent } from './pages/profile/edit-profile-supervisor/edit-profile-supervisor.component';
import { DialogsModule } from './dialogs/dialogs.module';
import { PipesModule } from './pipes/pipes.module';
import { ProfilePhotoComponent } from './pages/profile/profile-photo/profile-photo.component';
import { HomeDynamicDirective } from './directives/home-dynamic.directive';
import { PdfFormatsComponent } from './pages/pdf-formats/pdf-formats.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    ProfilePhotoComponent,
    EditProfileSupervisorComponent,
    PdfFormatsComponent,
    HomeDynamicDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    ComponentsModule,
    LayoutModule,
    DialogsModule,
    PipesModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
