import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-static-info',
  templateUrl: './static-info.component.html',
  styleUrls: ['./static-info.component.scss']
})
export class StaticInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
