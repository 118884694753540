<div class="profile-section">
    <div class="tb-column tb-align-center">
        <img class="avatar-no-pic" [src]="currentPic"/>
        <mat-spinner class="absolute" color="accent" diameter="50" *ngIf="isLoading"></mat-spinner>
    </div>
    <div class="tb-column tb-align-center">
        <button type="button" *ngIf="currentPic === 'assets/images/user-doctor-none.png'; else changeProfPic">
            Subir foto
        </button>
    </div>
    <ng-template #changeProfPic>
        <button type="button" mat-raised-button (click)="fileInput.click()" class="tb-text-14 upload-photo-button tb-white-two">
          Editar
        </button>
      </ng-template>
      <input hidden type="file" #fileInput accept=".png, .jpg, .jpeg" (change)="showPreview($event, 'add', 'profile')" />

      <p class="idRhodium">
        ID EMPRENDAMOS
      </p>
      <div class="tb-row tb-align-center container-id cursor-pointer" (click)="handleClickCopy()">
        <p class="contentId">
            {{userId}}
        </p>
        <mat-icon class="ic-copy" svgIcon="ic-copy"></mat-icon>
      </div>
</div>