import { Component, OnInit } from '@angular/core';
import { FormGroup,  Validators, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { delay } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ProfileService } from './profile.service';
import { EditProfileSupervisorComponent } from './edit-profile-supervisor/edit-profile-supervisor.component';
import { EMAIL_REGEX } from 'src/app/shared/data/data';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public subs = new SubSink();
  public profileForm: FormGroup;
  public msg;
  public profileData;
  public dialogRef: any;
  public isLoading: boolean = false;
  public isMobile: boolean = true;


  constructor(
    private breakpointObserver: BreakpointObserver,
    private form: FormBuilder,
    public profile: ProfileService,
    private dialog: MatDialog,
    
  ) { }

  ngAfterViewInit(){
    this.breakpointObserver
    .observe(['(min-width: 876px)'])
    .pipe(delay(1))
    .subscribe((res) => {
      //700px
      if (res.matches) {
        this.isMobile = true;
      }else {
        this.isMobile = false;
      }
    })
  }
  ngOnInit(): void {
    this.getProfile();

    this.profileForm = this.form.group({
      Id: ['', [
        Validators.required
      ]],
      name: ['', [
        Validators.required
      ]],
      email: ['', [
        Validators.required,
        Validators.pattern(EMAIL_REGEX)
      ]],
      phone: ['', [
        Validators.required
      ]],
      profile: ['', [
        Validators.required
      ]]
    })
  }

  public async getProfile() {
    try {
      this.isLoading = true;
      const response: any = await this.profile.getProfile();

      if(response.status === 'ok'){
        this.profileData = response.results;
        this.patchForm();
      }
      
      
    } catch (error) {
      this.isLoading = false;
    }
  }

  public patchForm() {
    this.profileForm.patchValue({
      Id: this.profileData.uuid,
      name: this.profileData.name + ' ' + this.profileData.last_name,
      email: this.profileData.email,
      phone: this.profileData.code_tel + ' ' +  this.profileData.tel,
      profile: this.profileData.organizations[0].organization.uuid
    });
    this.profileForm.controls['Id'].disable();
    this.profileForm.controls['email'].disable();
    this.profileForm.controls['name'].disable();
    this.profileForm.controls['phone'].disable();
    this.profileForm.controls['profile'].disable();
    this.isLoading = false;
  }

  public openDialog(){
    this.dialogRef = this.dialog.open(EditProfileSupervisorComponent, {
      data: {
        name: this.profileData.name,
        last_name: this.profileData.last_name,
        uuid: this.profileData.uuid
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      this.getProfile();
    })
  }

}
