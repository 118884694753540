import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '../material.module';
import { LayoutComponent } from './layout.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { FooterComponent } from './footer/footer.component';
import { ActionbarComponent } from './actionbar/actionbar.component';
import { OrganizationSelectComponent } from './toolbar/organization-select/organization-select.component';



@NgModule({
  declarations: [
    LayoutComponent,
    ToolbarComponent,
    SidenavComponent,
    FooterComponent,
    ActionbarComponent,
    OrganizationSelectComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule
  ], 
  exports: [
    LayoutComponent,
  ]
})
export class LayoutModule { }
