<div *ngIf="isLoading" class="tb-spinner">
    <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
</div>
<h2 mat-dialog-title *ngIf="data">Editar Beneficiario</h2>
<h2 mat-dialog-title *ngIf="!data">Agregar Beneficiario</h2>
<section mat-dialog-content>
    <form class="width-100" [formGroup]="editBeneficiariesForm">
        <mat-form-field color="accent" class="width-100" appearance="outline">
            <mat-label>
                Nombre
            </mat-label>
            <input type="text" formControlName="name" matInput required />
            <mat-error *ngIf="editBeneficiariesForm.get('name').hasError('required')">
                Campo requerido
            </mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="width-100" appearance="outline">
            <mat-label>
                Apellidos
            </mat-label>
            <input type="text" formControlName="lastName" matInput required />
            <mat-error *ngIf="editBeneficiariesForm.get('lastName').hasError('required')">
                Campo requerido
            </mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="width-100" appearance="outline">
            <mat-label>
                CURP
            </mat-label>
            <input type="text" formControlName="curp" matInput/>
            <mat-error *ngIf="editBeneficiariesForm.get('curp').hasError('pattern')">
                Ingrese una CURP Válida
            </mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="width-100" appearance="outline">
            <mat-label>RFC</mat-label>
            <input type="text" formControlName="rfc" matInput/>
            <mat-error *ngIf="editBeneficiariesForm.get('rfc').hasError('pattern')">
                Ingrese una RFC Válida
            </mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="width-100" appearance="outline">
            <mat-label>
                Correo
            </mat-label>
            <input type="text" formControlName="email" matInput required />
            <mat-error *ngIf="editBeneficiariesForm.get('email').hasError('required')">
                Campo requerido
            </mat-error>
            <mat-error *ngIf="editBeneficiariesForm.get('email').hasError('pattern')">
                Ingrese un correo Válido
            </mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="width-100" appearance="outline">
            <mat-label>
                Telefono
            </mat-label>
            <input type="text" formControlName="phone" matInput required />
            <mat-error *ngIf="editBeneficiariesForm.get('phone').hasError('required')">
                Campo requerido
            </mat-error>
            <mat-error *ngIf="editBeneficiariesForm.get('phone').hasError('pattern')">
                Ingrese un Telefono Válido
            </mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="width-100" appearance="outline">
            <mat-label>
                Número de póliza
            </mat-label>
            <input type="text" formControlName="insurance" matInput required />

        </mat-form-field>

        <div class="tb-row tb-justify-sb">
            <button class="buttonCancel" mat-raised-button mat-dialog-close>
                Cancelar
            </button>
            <button *ngIf="data" class="buttonAceppt" mat-raised-button (click)="editBeneficiaries()">
                Editar
            </button>
            <button *ngIf="!data" class="buttonAceppt" mat-raised-button (click)="addBeneficiaries()">
                Guardar
            </button>
        </div>
    </form>
</section>