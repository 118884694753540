import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SupervisorsService } from 'src/app/pages/supervisors/supervisors.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { SNACKBAR_RESULT } from 'src/app/shared/data/data';

@Component({
  selector: 'app-delete-supervisors',
  templateUrl: './delete-supervisors.component.html',
  styleUrls: ['./delete-supervisors.component.scss']
})
export class DeleteSupervisorsComponent implements OnInit {
  public isLoading = false;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private supervisorsService: SupervisorsService,
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
  ) { 
    this.data = data;
  }

  ngOnInit(): void {
  }

  public deleteSupervisors(){

    try {
      this.isLoading = true;
      const response: any = this.supervisorsService.deleteSupervisors(this.data.uuid);
      
      if(response){
        this.snackbarService.openSnackBar('Se ha eliminado correctamente.',SNACKBAR_RESULT.success);
        this.dialog.closeAll();
      }
      this.isLoading = false;
      
    } catch (error) {
      this.isLoading = false;
      this.snackbarService.openSnackBar('Hubo un problema al eliminar, intenta de nuevo más tarde.',SNACKBAR_RESULT.fail);
    }


  }

}
