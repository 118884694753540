<div *ngIf="isLoading" class="tb-spinner">
    <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
</div>
<div class="title" mat-dialog-title> Eliminar Supervisor</div>

<div mat-dialog-content>
    <p class="description">
        ¿Esta seguro de Eliminar al Supervior?
    </p>
    <div class="tb-row tb-justify-sb">
        <button class="buttonCancel" mat-raised-button mat-dialog-close>
            Cancelar
        </button>
        <button class="buttonAceppt" mat-raised-button (click)="deleteSupervisors()">
            Eliminar
        </button>

    </div>

</div>