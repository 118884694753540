import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { delay } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { PassDataService } from 'src/app/shared/services/pass-data.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  public toggle : boolean;
  public subs = new SubSink();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private passDataService: PassDataService
  ) { 
    this.subs.add(
      this.passDataService.currentMessage.subscribe( message => {
        if(message === 'openNavbar'){
          this.toggle = true;
        } else if (message === 'closeNavbar') {
          this.toggle = false;
        }
      })
    )
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    this.breakpointObserver
    .observe(['(min-width: 985px)'])
    .pipe(delay(1))
    .subscribe((res) => {
      //700px
      if(this.isLoggerd()){
        if (res.matches) {
          this.toggle = true;
        }else {
          this.toggle = false;
        }
      }
    })
  }

  public isLoggerd(){
    return localStorage.getItem('tokenCare') ? true : false;
  }

}
