import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardnumber'
})
export class CardNumberPipe implements PipeTransform {

  transform(value: any) {
    if(value){
      return value.replace(/([0-9]{4})/g, '$1 ');
    }else {
      return '#### #### ####';
    }
  }

}
