<div class="static">
    <span class="title-card color-black font-weigth-500">
        Semáforo de Riesgo Corporativo
    </span>
    
    <div class="statics-counters">
        <div *ngIf="isLoading" class="margin-top-5">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

        <iframe class="content-iframe" #ContentIframe>

        </iframe>

        <div class="content-button-report" *ngIf="existsReport">
            <button (click)="dialogPreviewPDF()" mat-raised-button class="access-report">ACCESO A REPORTE</button>
        </div>
    </div>
</div>