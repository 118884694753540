import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup,  Validators, FormBuilder, FormControl } from '@angular/forms';
import { BeneficiariesService } from 'src/app/pages/beneficiaries/beneficiaries.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { SNACKBAR_RESULT } from 'src/app/shared/data/data';

import { 
  CURP_REGEX, 
  EMAIL_REGEX,
  PHONE_REGEX,
  PHONE_MIN_LENGTH,
  PHONE_MAX_LENGTH,
  NAME_MIN_LENGTH,
  NAME_MAX_LENGTH,
  LAST_NAME_MIN_LENGTH,
  LAST_NAME_MAX_LENGTH,
  RFC_REGEX
}  from 'src/app/shared/data/data';

@Component({
  selector: 'app-edit-beneficiaries',
  templateUrl: './edit-beneficiaries.component.html',
  styleUrls: ['./edit-beneficiaries.component.scss']
})
export class EditBeneficiariesComponent implements OnInit {
  public editBeneficiariesForm: FormGroup;
  public isLoading: boolean = false;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public form: FormBuilder,
    public beneficiaries: BeneficiariesService,
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    this.editBeneficiariesForm = this.form.group({
      name: ['', [
        Validators.required,
        Validators.minLength(NAME_MIN_LENGTH),
        Validators.maxLength(NAME_MAX_LENGTH),
      ]],
      lastName: ['', [
        Validators.required,
        Validators.minLength(LAST_NAME_MIN_LENGTH),
        Validators.maxLength(LAST_NAME_MAX_LENGTH),
      ]],
      curp: ['', [
        Validators.minLength(18),
        Validators.maxLength(18),
        Validators.pattern(CURP_REGEX)
      ]],
      rfc: ['',  [
        Validators.pattern(RFC_REGEX)
      ]],
      email: ['', [
        Validators.required,
        Validators.pattern(EMAIL_REGEX)
      ]],
      phone: ['', [
        Validators.required,
        Validators.pattern(PHONE_REGEX),
        Validators.minLength(PHONE_MIN_LENGTH),
        Validators.maxLength(PHONE_MAX_LENGTH),
      ]],
      insurance: [''],
    });

    if(this.data){
      this.setValue();
    }
  }

  public setValue(){
    this.editBeneficiariesForm.setValue({
      name: this.data.name,
      lastName: this.data.lastName,
      curp: this.data.CURP === 'SIN_CURP' ? '' : this.data.CURP,
      email: this.data.email,
      phone: this.data.phone,
      insurance: this.data.insurance,
      rfc: this.data.RFC
    });
    if(this.data.status != 'null'){
      this.editBeneficiariesForm.controls['name'].disable();
      this.editBeneficiariesForm.controls['lastName'].disable();
      this.editBeneficiariesForm.controls['curp'].disable();
      this.editBeneficiariesForm.controls['email'].disable();
      this.editBeneficiariesForm.controls['phone'].disable();
      this.editBeneficiariesForm.controls['rfc'].disable();
      /* this.editBeneficiariesForm.controls['insurance'].disable(); */
    }
  }

  public async editBeneficiaries(){
    try {
      if(this.editBeneficiariesForm.value.curp !='' || this.editBeneficiariesForm.value.rfc != ''){
        if(this.editBeneficiariesForm.valid){
          this.isLoading = true;
          const response: any = await this.beneficiaries.editBeneficiaries(this.editBeneficiariesForm.value, this.data.id);
          if(response.status === 'Ok'){
            this.isLoading = false;
            this.snackbarService.openSnackBar('Se ha guardado correctamente.',SNACKBAR_RESULT.success);
            this.dialog.closeAll();
          }
  
        }else {
          this.snackbarService.openSnackBar('Datos Incompletos.',SNACKBAR_RESULT.fail);
        }

      } else {
        this.snackbarService.openSnackBar('Ingrese la CURP O RFC.', SNACKBAR_RESULT.fail);
      }
      
    } catch (error) {
      this.snackbarService.openSnackBar('Hubo un problema al guardar los cambios, intenta de nuevo más tarde.',SNACKBAR_RESULT.fail);
    }
  }

  public async addBeneficiaries(){
    try {
      if(this.editBeneficiariesForm.value.curp != '' || this.editBeneficiariesForm.value.rfc !='') {
        if(this.editBeneficiariesForm.valid){
          this.isLoading = true;
          const response: any  = await  this.beneficiaries.addBeneficiaries(this.editBeneficiariesForm.value);
          
          if(response.status === 'Ok'){
            this.isLoading = false;
            this.snackbarService.openSnackBar('Se ha guardado correctamente.',SNACKBAR_RESULT.success);
            this.dialog.closeAll();
          }
  
        }else {
          this.snackbarService.openSnackBar('Datos Incompletos.',SNACKBAR_RESULT.fail);
        }

      } else {
        this.snackbarService.openSnackBar('Ingrese la CURP O RFC.', SNACKBAR_RESULT.fail);
      }
      
    } catch (error) {
      this.isLoading = false;
      this.snackbarService.openSnackBar('Hubo un problema al guardar los cambios, intenta de nuevo más tarde.',SNACKBAR_RESULT.fail);
    }

  }

}
