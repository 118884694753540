import { MatSnackBarConfig } from '@angular/material/snack-bar';


/* Snackbar */
export const SNACKBAR_CONFIG: MatSnackBarConfig = {
  duration: 10000,
  horizontalPosition: 'end',
  verticalPosition: 'bottom',
};
export const SNACKBAR_RESULT = {
  fail: 2,
  success: 1,
};


/* Regex */
export const BLOOD_PRESSURE_REGEX = /^[0-9]{1,3}\/[0-9]{1,3}$/;
export const CP_REGEX = '[0-9]{4,5}';
export const CURP_REGEX = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NAME_REGEX = '[a-zA-ZáéíóúÁÉÍÚÓñÑüÜ ]*$';
export const PASSWORD_REGEX = '[a-zA-Z0-9]*';
export const PHONE_REGEX = '^[+]?[0-9]*$';
export const RFC_REGEX = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
export const LOWER_CASE_REGEX = /(?=.*[a-z])/;
export const UPPER_CASE_REGEX = /(?=.*[A-Z])/;
export const NUMERIC_REGEX = /(?=.*[0-9])/;
export const SPECIAL_REGEX = /(?=.*[\^$*.[\]{}()?“!@#%&/,><’:|_~`/-])/;


/* MaxLength & MinLength Validations */
export const AWARD_MAX_LENGTH = 40;
export const AWARD_MIN_LENGTH = 3;
export const CERTIFICATE_MAX_LENGTH = 20;
export const CERTIFICATE_MIN_LENGTH = 3;
export const EMAIL_MAX_LENGTH = 50;
export const HOSPITAL_MAX_LENGTH = 50;
export const HOSPITAL_MIN_LENGTH = 3;
export const LANGUAGE_MAX_LENGTH = 40;
export const LANGUAGE_MIN_LENGTH = 3;
export const LAST_NAME_MAX_LENGTH = 40;
export const LAST_NAME_MIN_LENGTH = 3;
export const NAME_MAX_LENGTH = 40;
export const NAME_MIN_LENGTH = 3;
export const PASS_MAX_LENGTH = 30;
export const PASS_MIN_LENGTH = 15;
export const PHONE_MAX_LENGTH = 13;
export const PHONE_MIN_LENGTH = 8;
export const PROF_LICENSE_MAX_LENGTH = 8;
export const PROF_LICENSE_MIN_LENGTH = 5;
export const REGISTER_NUMBER_MAX_LENGTH = 15;
export const REGISTER_NUMBER_MIN_LENGTH = 3;
export const SEC_LAST_NAME_MAX_LENGTH = 40;
export const SEC_LAST_NAME_MIN_LENGTH = 3;
export const SPECIALITY_MAX_LENGTH = 50;
export const SPECIALITY_MIN_LENGTH = 3;
export const UNIVERSITY_MAX_LENGTH = 50;
export const UNIVERSITY_MIN_LENGTH = 3;

/* Tokens */
export const jwtALg = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRoIjoidGVlYl9zZXJ2aWNlcyIsImlhdCI6MTU5ODYzMTg3MSwiZXhwIjoxNjI5NzM1ODcxfQ.SaIcl3aDMHNWOWuB9akBopFdFf7BcEPfQZ1PPr7ZALg`;