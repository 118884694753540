<div *ngIf="isLoading" class="tb-spinner">
    <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
</div>
<h2 mat-dialog-title>Agregar Supervisor</h2>
<section mat-dialog-content>

    <div class="description">
        <span>
            Al enviar la invitación a un supervisor que ya tenga una cuenta de usuario se vinculará automáticamente a esta organización.
        </span>
    </div>
    <form class="width-100" [formGroup]="addSupervisorsForm">
        <mat-form-field color="accent" class="width-100" appearance="outline">
            <mat-label>
                Correo
            </mat-label>
            <input type="text" formControlName="email" matInput required/>
            <mat-error *ngIf="addSupervisorsForm.get('email').hasError('required')">
                Campo requerido
            </mat-error>
        </mat-form-field>
        
        <mat-form-field color="accent" class="width-100" appearance="outline">
            <mat-label>
                Perfil
            </mat-label>
            <mat-select formControlName="profile" required>
                <mat-option *ngFor="let profile of profileValue" [value]="profile.value">
                    {{ profile.text }}
                </mat-option>

            </mat-select>
            <mat-error *ngIf="addSupervisorsForm.get('profile').hasError('required')">
                Campo requerido
            </mat-error>
        </mat-form-field>

        <div class="tb-row tb-justify-sb">
            <button class="buttonCancel" mat-raised-button mat-dialog-close>
                Cancelar
            </button>
            <button class="buttonAceppt" mat-raised-button (click)="addSupervisor()">
                Guardar
            </button>
        </div>
    </form>
</section>