<div *ngIf="isLoading" class="tb-spinner">
    <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
</div>
<h2 mat-dialog-title>Editar Supervisor</h2>
<section mat-dialog-content>
    <form class="width-100" [formGroup]="editSupervisorsForm">
        <mat-form-field color="accent" class="width-100" appearance="outline">
            <mat-label>
                Nombre
            </mat-label>
            <input type="text" formControlName="name" matInput required/>
            <mat-error *ngIf="editSupervisorsForm.get('name').hasError('required')">
                Campo requerido
            </mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="width-100" appearance="outline">
            <mat-label>
                Apellidos
            </mat-label>
            <input type="text" formControlName="lastName" matInput required/>
            <mat-error *ngIf="editSupervisorsForm.get('lastName').hasError('required')">
                Campo requerido
            </mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="width-100" appearance="outline">
            <mat-label>
                Perfil
            </mat-label>
            <mat-select formControlName="profile" required>
                <mat-option *ngFor="let profile of profileValue" [value]="profile.value">
                    {{ profile.text }}
                </mat-option>

            </mat-select>
            <mat-error *ngIf="editSupervisorsForm.get('profile').hasError('required')">
                Campo requerido
            </mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="width-100" appearance="outline">
            <mat-label>
                Estado
            </mat-label>
            <mat-select formControlName="status" required>
                <mat-option *ngFor="let status of statusValue" [value]="status.value">
                    {{ status.text }}
                </mat-option>

            </mat-select>
            <mat-error *ngIf="editSupervisorsForm.get('status').hasError('required')">
                Campo requerido
            </mat-error>
        </mat-form-field>

        <div class="tb-row tb-justify-sb">
            <button class="buttonCancel" mat-raised-button (click)="closetEdit()">
                Cancelar
            </button>
            <button class="buttonAceppt" mat-raised-button (click)="editSupervisor()">
                Guardar
            </button>
        </div>
    </form>
</section>