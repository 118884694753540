<ng-template #content><ng-content></ng-content></ng-template>

<div  class="LayoutContainer">
    <div  class="Layout layoutContainer-toggle">
        <app-toolbar id="toolbar"></app-toolbar>
        <app-sidenav *ngIf="toggle" class="sidenav"></app-sidenav>

        <div [ngClass]="isLoggerd() ? 'content': 'content-logger'" class="content" #content>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>

    </div>

</div>
