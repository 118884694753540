
<div class="container">
    <span class="titleCard">
        Semáforo de Salud Individual
    </span>
    <div *ngIf="isLoading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <iframe *ngIf="existQsessionTeeb" class="content-iframe" #ContentIframeIn>

    </iframe>
    <div *ngIf="!existQsessionTeeb" class="content-iframe noExistQsessionTeeb titleCard" >
        <p>Aún no ha contestado el Cuestionario de Reporte de salud.</p>
    </div>
    <div class="content-button-report" *ngIf="existsReport">
        <button (click)="dialogPreviewPDF()" mat-raised-button class="access-report">ACCESO A REPORTE</button>
    </div>
</div>