import { 
  Component,
  OnInit, 
  ViewChild,
  ViewContainerRef,
  ComponentFactory,
  ComponentFactoryResolver,
  
} from '@angular/core';
import { SubSink } from 'subsink';
import { MatDialog } from '@angular/material/dialog';
import { PassDataService } from 'src/app/shared/services/pass-data.service';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { SNACKBAR_RESULT } from 'src/app/shared/data/data';
import { OrganizationSelectComponent } from 'src/app/layout/toolbar/organization-select/organization-select.component';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { Router } from '@angular/router';
import { HomeDynamicDirective } from 'src/app/directives/home-dynamic.directive';
import { HealthTrafficLightHomeComponent } from 'src/app/components/health-traffic-light-home/health-traffic-light-home.component';
import { NoOrganizationsComponent } from 'src/app/dialogs/no-organizations/no-organizations.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  //@ViewChild('dynamicHomeContent', { read: ViewContainerRef}) dynamicHomeContent: ViewContainerRef ;
  //ya funciona
  //@ViewChild(HomeDynamicDirective, {static: true}) dynamic!: HomeDynamicDirective;
  @ViewChild('dynamicHomeContent', { read: ViewContainerRef})  dynamicHomeContent: ViewContainerRef;
  public organizationName = '';
  public today = new Date();
  public imageOrganization = 'assets/images/welcome-title.png';
  public organizationsData;
  public nameOrganizationSelect;
  public imageURLOrganizationSelect = 'assets/images/user-none.png';
  public consultations: any;
  public isLoading = false;
  public homeSection = [];
  public contentRightSection: boolean = true;
  public contentRightTopSection: boolean = true;
  public contentLeftSection: boolean;
  public allowedDomain = 'ooo.rhodium.emprendamos';
  

  private subs = new SubSink();

  constructor(
    private passDataService: PassDataService,
    public profile: ProfileService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.subs.add(
      this.passDataService.currentMessage.subscribe( message => {
        if(message === 'selectOrganization'){
          this.getOrganization();
        }
      })
    )
  }

  ngOnInit(): void {
    /* this.getOrganization(); */
    this.getProfile();
  }

  ngAfterViewInit(): void {
    this.getOrganization();
    //this.rightSection();
    //this.generateComponet();
  }

  public getOrganization(){
    const organizationData = JSON.parse(localStorage.getItem('organizationData'));

    this.homeSection = [];
    
    if(organizationData.organization?.web_config?.sections?.homeSection){
      this.homeSection = organizationData.organization?.web_config?.sections?.homeSection;
      this.rightSection();
    }
    

    if(organizationData){
      this.imageOrganization = organizationData.organization.image;
      this.organizationName = organizationData.organization.name;
    }
    
  }

  public async getProfile(){
    this.isLoading = true;
    try {
      const response: any = await this.profile.getProfile();

      //console.log('response', response);
      if(response.status === 'ok'){
        localStorage.setItem('profile', JSON.stringify(response.results));
        const organizaciones = [];
        for (let i = 0; i < response.results.organizations.length; i++) {
          //console.log('response.results.organizations[i].organization.domain_app', response.results.organizations[i].organization.domain_app);
          let domaint: any = response.results.organizations[i].organization.domain_app;
          let domaintData =  domaint.split(',');
          //console.log('domaintData', domaintData);
          if(domaintData.length == 2){
            //console.log('domaint[1] test', domaintData[1]);
            if(domaintData[1] === this.allowedDomain){
              //console.log('domaint[1]', domaintData[1]);
              organizaciones.push(response.results.organizations[i]);
            }
          }else if (domaintData.length == 1){
            if(domaintData[0] === this.allowedDomain){
              console.log('domaint[0]', domaintData[0]);
              organizaciones.push(response.results.organizations[i]);
            }
          }
        }
        //console.log('organizaciones', organizaciones);
        this.organizationsData = organizaciones;

        if(this.organizationsData.length === 0){
          
          this.noOrganizations();
          
          /* this.isLoading = false;
          this.snackbarService.openSnackBar('No tiene organizaciones asignadas', SNACKBAR_RESULT.fail);
          this.authService.logout(); */
        }

        for(let i = 0; i < this.organizationsData.length; i++){
          this.organizationsData[i].organization.image = await this.getPictureOrganization(this.organizationsData[i].organization.uuid);
        }

        if(this.organizationsData.length === 1){
          localStorage.setItem('organization', this.organizationsData[0].organization.uuid);
          this.setOrganization();
          this.isLoading = false;
        }else {
          const organization = localStorage.getItem('organization');
          if(!organization){
            this.isLoading = false;
            if(this.router.url != '/auth/login'){
              this.openDialog(this.organizationsData);
            }
          } else {
            this.isLoading = false;
            this.setOrganization();
          }
        }
      }
    } catch (error) {
      this.isLoading = false;
      if(error.error.status ==='error'){

        if(error.error.message === 'expired session'){
          this.snackbarService.openSnackBar('sesión expirada', SNACKBAR_RESULT.fail);
          this.authService.logout();
          //this.router.navigate(['/auth/login']);
        }

      }
    }
  }

  public openDialog(organization) {
    if(organization.length > 1){
      const dialogRef = this.dialog.open(OrganizationSelectComponent, {
        width: '400px',
        height: 'auto',
        data: { organization }
      });
      dialogRef.afterClosed().subscribe(result => {
        const organization = localStorage.getItem('organization');
  
        if(!organization){
          this.openDialog(this.organizationsData);
        }
        this.setOrganization();
        //this.passDataService.changeMessage('selectOrganization');
  
      });
    }
  }

  public noOrganizations() {
    const dialogRef = this.dialog.open(NoOrganizationsComponent, {
      width: '400px',
      height: 'auto',

    });
    dialogRef.afterClosed().subscribe(result => {
      const organization = localStorage.getItem('organization');
      if(!organization && this.organizationsData.length === 0 && this.router.url != '/auth/login'){
        this.noOrganizations();
      }
    })

  }

  async getPictureOrganization(idOrganization){
    try {
      const res: any = await this.profile.getPicture(idOrganization);
      if(res.status === 'ok'){
        
        return res.results[0].url_aws;
      } else {
        return 'assets/images/user-none.png';
      }
    } catch (error) {
      return 'assets/images/user-none.png';
    }
  }

  public setOrganization(){
    const organization = localStorage.getItem('organization');
    if(organization){
      /* this.organizationSelect.setValue(organization); */

      const organizationData = this.organizationsData.find((item) => {
        return item.organization.uuid === organization;
      });

      this.nameOrganizationSelect = organizationData.organization.name;
      localStorage.setItem('organizationData', JSON.stringify(organizationData));
      this.passDataService.changeMessage('selectOrganization');
      this.getPicture(organizationData.organization.uuid);
      
      
      this.snackbarService.openSnackBar('organizacion seleccionada', SNACKBAR_RESULT.success);

    } else {
      this.snackbarService.openSnackBar('no hay organizacion seleccionada', SNACKBAR_RESULT.fail);
    }
  }

  public async getPicture(idOrganization){
    try {
      const res: any = await this.profile.getPicture(idOrganization);
      if(res.status === 'ok'){
        
        this.imageURLOrganizationSelect = res.results[0].url_aws;
      }
    } catch (error) {
      
    }
  }

  public selectOrganization(organization:any){

    
    if(organization){
      localStorage.setItem('organization', organization.organization.uuid);
      this.setOrganization();
    }
  }


  /* generateComponet() {
    const viewContainerRef = this.dynamic.viewContainerRef;
    const componentRef = viewContainerRef.createComponent<any>(HealthTrafficLightHomeComponent);
  } */

  generateComponet(): void{

    // crear un elemento div  y agregar el contenedor
    const div = document.createElement('div');
    div.classList.add('section-3');
    div.innerText = 'Contenido del div';
    this.dynamicHomeContent.element.nativeElement.appendChild(div);

    // Crear un componente y agregarlo al contenedor
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(HealthTrafficLightHomeComponent);
    const componentRef = this.dynamicHomeContent.createComponent(componentFactory);

  }

  leftSection(){

  }

  rightSection(){
    const trafficlight = this.homeSection.includes('trafficlight');
    const cardMembership = this.homeSection.includes('card');
    if(trafficlight){
      //console.log('trafficlight', trafficlight);
      this.contentRightSection = trafficlight;
    } else {
      this.contentRightSection = trafficlight;
    }
    if(cardMembership) {
      //console.log('cardMembership', cardMembership);
      this.contentRightTopSection = cardMembership;
    }else {
      this.contentRightTopSection = cardMembership;
    }
  }

  
  


}
