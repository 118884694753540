<h2>Seleccione una organización</h2>
<mat-selection-list #shoes>
    <mat-list-option *ngFor="let item of data.organization" [value]="item.organization.uuid" (click)="selectOrganization(item.organization.uuid)">
        <div class="itemOrganization">
            <img *ngIf="item.organization.logo" src={{item.organization.image}}>
            <span>
                {{item.organization.name}}
            </span>
        </div>

    </mat-list-option>
</mat-selection-list>
