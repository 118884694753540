<div *ngIf="isLoading" class="tb-spinner">
    <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
</div>
<div class="content">
    <div class="list">
        <div class="content-title">
            <span class="content-search">
                <input type="text" [(ngModel)]="search" (input)="searchDocument(search)" placeholder="Buscar un documento"/>
            </span>
        </div>
        <div class="content-listado">
            <ul class="listado">
                <li *ngFor="let documen of documents">
                    <a (click)="downloadDocument(documen)">
                        <img src="assets/icons/ico_pdf.svg"/>
                        {{documen.data}}
                    </a>
                </li>

            </ul>
            <!-- <div  *ngFor="let documen of documents" class="list-content">
                <img src="assets/icons/ico_pdf.svg"/>
                <span>{{documen.data}}</span>
            </div> -->
        </div>
    </div>

</div>