<div #toolbar class="toolbar">
    <div class="buttonNavbar">
        <button mat-icon-button *ngIf="openNabvarButton" (click)="openNavbarMenu()">
            <mat-icon *ngIf="!openNavbar">
                menu
            </mat-icon>
            <mat-icon *ngIf="openNavbar">
                close
            </mat-icon>
        </button>
    </div>
    <div class="logo-Container">
        <img src="assets/images/brand-logo.svg" class="tb-logo" />
    </div>
    <div class="contentOrganization">
        <!-- <div class="organizationSelect" [matMenuTriggerFor]="menuOrganization">
            <img [src]="imageURLOrganizationSelect" />
            <span>
                {{nameOrganizationSelect}}
            </span>
        </div> -->
    </div>
    <mat-menu #menuOrganization="matMenu">
        <button *ngFor="let organization of organizationsData" mat-menu-item (click)="selectOrganization(organization)">
            <div class="itemOrganization">
                <img class="imgOrganization" src="{{ organization.organization.image}}" />

                <span>
                    {{ organization?.organization?.name}}
                </span>
            </div>
        </button>
    </mat-menu>


    <div *ngIf="isLoggerd()" class="imgperfil">
        <img class="tb-user-button cursor-pointer" [src]="imageURLprofile" [matMenuTriggerFor]="menu" style="margin-right: 20px ;" />
    </div>

    <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/profile">
            Mi cuenta
        </button>
        <button mat-menu-item (click)="logaout()">
            Cerrar sesión
        </button>
    </mat-menu>
</div>