<div *ngIf="isLoading" class="tb-spinner">
    <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
</div>
<div class="container" #dynamicHomeContent>
    <div [class]="{'section-1': contentRightTopSection, 'section-1-null-rigth-top': !contentRightTopSection}">
        <div class="welcome tb-row">
            <div class="width-100">
                <div class="tb-column">
                    <div [matMenuTriggerFor]="menuOrganization"  class="tb-row tb-align-center cursor-pointer">
                        <img class="image-title" src={{imageOrganization}}/>
                        <div class="tb-column">
                            <span class="name-organization">
                                {{organizationName}}
                            </span>
                            <span class="date-welcome">
                                <!-- Actualización: Jue 1 de Dic 00:00 pm  -->
                                Actualización: {{today | localeDatetime: 'LLLL'}} 
                            </span>
                        </div>
                    </div>
                    <mat-menu #menuOrganization="matMenu">
                        <button *ngFor="let organization of organizationsData" mat-menu-item (click)="selectOrganization(organization)">
                            <div class="itemOrganization">
                                <img class="imgOrganization" src="{{ organization.organization.image}}" />
                
                                <span>
                                    {{ organization?.organization?.name}}
                                </span>
                            </div>
                        </button>
                    </mat-menu>
                    <div class="content-description tb-column">
                        <span class="color-secondary font-weigth-500">
                            Bienvenido al Panel de Analítica
                        </span>
                        <div>
                            <span class="color-primary font-weigth-500">
                                de
                            </span>
                            <span class="color-primary font-weigth-700">
                                Emprendamos
                            </span>
                            <!-- <span class="tb-blue font-weigth-500">
                                de
                            </span>
                            <span class="tb-blue font-weigth-700">
                                Rhodium
                            </span> -->

                        </div>
                    </div>
                    <span class="organization-analytics font-weigth-700 color-primary">
                        ANALÍTICA DE TU ORGANIZACIÓN EN TIEMPO REAL
                    </span>
                </div>
            </div>
            <div>
                <img class="image-time" src="assets/images/time-management.png"/>
            </div>
        </div>
    </div>
    <div *ngIf="homeSection.includes('card')" class="section-2">
        <app-card-membership></app-card-membership>
    </div>
    <div *ngIf="homeSection.includes('trafficlight')" class="section-3">
        <app-health-traffic-light-home></app-health-traffic-light-home>
    </div>
    
    <div *ngIf="homeSection.includes('statistics')" [class]="{'section-4': contentRightSection, 'section-4-null-rigth': !contentRightSection}">
        <app-statistics-counters></app-statistics-counters>
    </div>
    <div *ngIf="homeSection.includes('footer')" [class]="{'section-5': contentRightSection, 'section-5-null-rigth': !contentRightSection}">
        <app-static-info></app-static-info>
    </div>
    <!-- <div class="section-3">
        <ng-template appHomeDynamic></ng-template>
    </div> -->
</div>
