import { Component, OnInit } from '@angular/core';
import { PdfFormatsService } from './pdf-formats.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pdf-formats',
  templateUrl: './pdf-formats.component.html',
  styleUrls: ['./pdf-formats.component.scss']
})
export class PdfFormatsComponent implements OnInit {
  public organization_UUID: string;
  public documents: any;
  public documentsArray: any;

  public search: string;
  public isLoading: boolean;

  constructor(
    private pdfUploadService: PdfFormatsService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.organization_UUID = this.router.url.split('/')[2];
    
    this.getDocumentsPublic();
  }


  public async getDocumentsPublic(){
    try {
      this.isLoading = true;
      const response: any = await this.pdfUploadService.getPDFFormats(this.organization_UUID);
      if(response.status === 'ok'){
        this.documents = response.result;
        this.documentsArray = response.result;
        this.isLoading = false;
      }
      
    } catch (error) {
      this.isLoading = false;
    }
  }

  public async downloadDocument(document: any){
    try {
      const response: any  = await this.pdfUploadService.downloadDocument(document.key_aws);
      if(response.status === 'ok'){
        window.open(response.results, '_blank');
      }
    } catch (error) {
    }
  }


  public searchDocument(event: any){

    if(event.length >= 3){
      var documents = this.documents;
  
      var search = this.documentsArray.filter(item => 
        item.data.toLowerCase().includes(event.toLowerCase())
      );
      this.documents = search;
    }else {
      this.getDocumentsPublic();

    }

  }

}
