import { 
  Component, 
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ProfileService } from 'src/app/pages/profile/profile.service';

import { OrganizationSelectComponent } from './organization-select/organization-select.component';
import { PassDataService } from 'src/app/shared/services/pass-data.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { SNACKBAR_RESULT } from 'src/app/shared/data/data';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @ViewChild('toolbar') toolbar: ElementRef;
  organizationSelect = new FormControl();
  public organizationsData;
  private subs = new SubSink();

  public imageURLprofile = 'assets/images/user-none.png';
  public imageURLOrganizationSelect = 'assets/images/user-none.png';

  public nameOrganizationSelect;
  public openNabvarButton = false;
  public openNavbar = false;

  constructor(
    public profile: ProfileService,
    private dialog: MatDialog,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private passDataService: PassDataService,
    private snackbarService: SnackbarService,
    private renderer: Renderer2
  ) { 
    this.subs.add(
      this.passDataService.currentMessage.subscribe( message => {
        if(message === 'selectOrganization'){
          this.getPictureUser();
          //this.colorChange();
        } else if (message === 'profileImageChanged'){
          this.getPictureUser();
        }
      })
    )
  }

  ngOnInit(): void {
    //this.getProfile();
    this.getPictureUser();
  }

  ngAfterViewInit(){
    this.breakpointObserver
    .observe(['(max-width: 985px)'])
    .pipe(delay(1))
    .subscribe((res) => {
      //700px
      if(this.isLoggerd()){
        if (res.matches) {
          this.openNabvarButton = true;
          this.openNavbar = false;
        }else {
          this.openNabvarButton = false;
          this.openNavbar = false;
        }
      }
    })
  }

  public async getProfile() {
    try {
      const response: any = await this.profile.getProfile();
      
      if(response.status === 'ok'){
        localStorage.setItem('profile', JSON.stringify(response.results));
        
        this.organizationsData = response.results.organizations;
        for(let i = 0; i < this.organizationsData.length; i++){
          this.organizationsData[i].organization.image = await this.getPictureOrganization(this.organizationsData[i].organization.uuid);
        }
        
        
        if(this.organizationsData.length === 1) {
          localStorage.setItem('organization', this.organizationsData[0].organization.uuid);
          this.setOrganization();
          //this.passDataService.changeMessage('selectOrganization');

        } else {
          const organization = localStorage.getItem('organization');
          if(!organization){
            this.openDialog(this.organizationsData);
          } else {
            this.setOrganization();
          }
        }
      }
    } catch (error) {
      
    }
  }

  public logaout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  public openDialog(organization) {
    const dialogRef = this.dialog.open(OrganizationSelectComponent, {
      width: '400px',
      height: 'auto',
      data: { organization }
    });
    dialogRef.afterClosed().subscribe(result => {
      const organization = localStorage.getItem('organization');

      if(!organization){
        this.openDialog(this.organizationsData);
      }
      this.setOrganization();
      //this.passDataService.changeMessage('selectOrganization');

    });
  }

  public selectOrganization(organization:any){

    
    if(organization){
      localStorage.setItem('organization', organization.organization.uuid);
      this.setOrganization();
    }
  }

  public setOrganization(){
    const organization = localStorage.getItem('organization');
    if(organization){
      /* this.organizationSelect.setValue(organization); */

      const organizationData = this.organizationsData.find((item) => {
        return item.organization.uuid === organization;
      });

      this.nameOrganizationSelect = organizationData.organization.name;
      localStorage.setItem('organizationData', JSON.stringify(organizationData));
      this.passDataService.changeMessage('selectOrganization');
      this.getPicture(organizationData.organization.uuid);
      this.snackbarService.openSnackBar('organizacion seleccionada', SNACKBAR_RESULT.success);

    } else {
      this.snackbarService.openSnackBar('no hay organizacion seleccionada', SNACKBAR_RESULT.fail);
    }
  }

  public openNavbarMenu(){
    
    this.openNavbar = !this.openNavbar;
    if(this.openNavbar){
      this.passDataService.changeMessage('openNavbar');
    }else {
      this.passDataService.changeMessage('closeNavbar');
    }
  }

  async getPictureOrganization(idOrganization){
    try {
      const res: any = await this.profile.getPicture(idOrganization);
      if(res.status === 'ok'){
        
        return res.results[0].url_aws;
      } else {
        return 'assets/images/user-none.png';
      }
    } catch (error) {
      return 'assets/images/user-none.png';
    }
  }
  

  public async getPicture(idOrganization){
    try {
      const res: any = await this.profile.getPicture(idOrganization);
      if(res.status === 'ok'){
        
        this.imageURLOrganizationSelect = res.results[0].url_aws;
      }
    } catch (error) {
      
    }
  }

  public async getPictureUser(){
    try {
      
      const res: any = await this.profile.getProfileImage();
      if(res.status === 'ok'){
          if(res.results){
            this.imageURLprofile = res.results;
          }else {
            this.imageURLprofile = 'assets/images/user-none.png';
          }
      }
    } catch (error) {
      this.imageURLprofile = 'assets/images/user-none.png';
    }
  }

  public colorChange(){
    const element = this.toolbar.nativeElement;
    //const color = element.style.backgroundColor;
    this.renderer.setStyle(element, 'background', 'red');
    //console.log(color);
    /* if(color === 'rgb(255, 255, 255)'){
      this.renderer.setStyle(element, 'background-color', '#1c1c1c');
    } else {
      this.renderer.setStyle(element, 'background-color', '#ffffff');
    } */
  }

  public isLoggerd(){
    return localStorage.getItem('tokenCare') ? true : false;
  }



}
