import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { jwtALg } from 'src/app/shared/data/data';


@Injectable({
  providedIn: 'root'
})
export class BeneficiariesService {
  private URL: string = environment.appApiUrl;
  private rootURL: string = environment.rootUrl;
  public wsURL: string = environment.wsURL;
  public appApiUrlHealth: string = environment.appApiUrlHealth;
  public rootUrl: string = environment.rootUrl;
  private headerALG = {
    Authorization: `Bearer ${jwtALg}`
  }

  constructor(
    private http: HttpClient
  ) { }

  getBeneficiaries(page, size) {
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const URL = `${this.URL}/collaborator-teeb-care?page=${page}&size=${size}`;
    //const URL = `${this.URL}/collaborator-teeb-care/data/?page=${page}&size=${size}`;

    const headers = {
      Authorization: token,
      organization: organization
    };

    return this.http.get(URL, { headers }).toPromise();
  }


  public uploadFileCSV(file:any){
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const URL = `${this.URL}/pending-collaborator-teeb-care/csv`;
    const headers = {
      Authorization: token,
      organization: organization
    };

    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(URL, formData, { headers }).toPromise();
  }

  public addBeneficiaries(data){
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const URL = `${this.URL}/pending-collaborator-teeb-care`;
    const headers = {
      Authorization: token,
      organization: organization
    };
    const body = {
      name: data.name,
      last_name: data.lastName,
      CURP: data.curp ? data.curp : 'SIN_CURP',
      RFC: data.rfc,
      email: data.email,
      phone: data.phone,
      uuid_organization: organization,
      insurance_number: data.insurance,
    };

    return this.http.post(URL, body, { headers }).toPromise();
  }

  public editBeneficiaries(data, id){
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const URL = `${this.URL}/collaborator-teeb-care/update-insure-number?uuid_pending_collaborator=${id}`;
    const headers = {
      Authorization: token,
      organization: organization
    };
    const body = {
      name: data.name,
      last_name: data.lastName,
      CURP: data.curp ? data.curp : 'SIN_CURP',
      RFC: data.rfc,
      email: data.email,
      phone: data.phone,
      uuid_organization: organization,
      insurance_number: data.insurance,
    };

    return this.http.put(URL, body, { headers }).toPromise();

  }

  public deleteBeneficiaries(id){
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const URL = `${this.URL}/pending-collaborator-teeb-care/${id}`;
    const headers = {
      Authorization: token,
      organization: organization
    };

    return this.http.delete(URL, { headers }).toPromise();
  }

  public getCollaborator(id){
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const URL = `${this.URL}/collaborator-teeb-care/data/${id}`;
    const headers = {
      Authorization: token,
      organization: organization
    };

    return this.http.get(URL, { headers }).toPromise();

  }

  public getCollaboratorTeebCare(page, size, search, filter){
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    
    var URL;
    if(search) {
      URL = `${this.URL}/pending-collaborator-teeb-care/oganitation/${organization}?page=${page}&size=${size}&search=${search}`;
    }else {
      URL = `${this.URL}/pending-collaborator-teeb-care/oganitation/${organization}?page=${page}&size=${size}&status=${filter}`;
    }
    const headers = {
      Authorization: token,
      organization: organization
    };

    return this.http.get(URL, { headers }).toPromise();

  }

  public getBeneficiariosAll(){
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const URL = `${this.URL}/pending-collaborator-teeb-care/organitation-full/${organization}`;
    const headers = {
      Authorization: token,
      organization: organization
    };
    return this.http.get(URL, {headers}).toPromise();
  }

  public getOcupation(patientID){
    const URL = `${this.rootURL}/profile/occupation/${patientID}`;
    return this.http.get(URL, {headers: this.headerALG}).toPromise();
  }

  public getReligion(patientID){
    const URL = `${this.rootURL}/profile/marital/${patientID}`;
    return this.http.get(URL, {headers: this.headerALG}).toPromise();
  }

  public getInsurance(patientID){
    const URL = `${this.rootURL}/profile/insurance/${patientID}`;
    return this.http.get(URL, {headers: this.headerALG}).toPromise();
  }

  public getContacts(patientID){
    const URL = `${this.rootURL}/profile/contact/${patientID}`;
    return this.http.get(URL, {headers: this.headerALG}).toPromise();
  }

  public getProfilePic(patientID){
    const token = localStorage.getItem('tokenCare');
    const url = `${this.rootURL}/v1.2/api/bucket/getFiles/patients/${patientID}/profile_pic`;
    const headers = {
      Authorization: token
    };
    return this.http.get(url, { headers }).toPromise();
  }

  public getConsultationsSpecialtybyUser(patientID){
    const URL = `${this.wsURL}/api/user/info/${patientID}`;
    return this.http.get(URL).toPromise();

  }

  public supervisorTeebCareDasboard(){
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const headers = {
      Authorization: token,
      organization: organization
    }
    const url = `${this.URL}/supervisor-teeb-care/dashboard/${organization}`;
    return this.http.get(url, { headers }).toPromise();
  }

  public supervisorTeebCarePatientData(patiendID){
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    //const profile = JSON.parse(localStorage.getItem('profile'));
    const headers = {
      Authorization: token,
      organization: organization
    }
    const url = `${this.URL}/supervisor-teeb-care/patient_data_appoiment/${patiendID}`;
    return this.http.get(url, { headers }).toPromise();
  }

  public smartCheckup(){
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const headers = {
      Authorization: token
    }
    const url = `${this.appApiUrlHealth}q/stats/reporte-de-salud/${organization}/`;
    return this.http.get(url, { headers }).toPromise();

  }

  public sumQueries() {
    const token = localStorage.getItem('tokenCare') || '';
    const organization = localStorage.getItem('organization');
    const headers = {
      Authorization: `${token}`
    };

    const URL = `${this.wsURL}/api/organization/info/${organization}/`;
    //https://dev-ws.rhodium.health/api/organization/info/4b3b3512-4132-46ef-9527-dd241bef523b/

    return this.http.get(URL,{ headers}).toPromise();
  }

  public getReportHeald(){
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const headers = {
      'Access-Control-Allow-Origin': '*',
      Authorization: token
    };
    const url = `${this.rootUrl}/v1.2/api/bucket/supervisor/get-result-link-organitation/${organization}`;
    //https://dev-backend.rhodium.health/v1.2/api/bucket/supervisor/get-result-link-organitation/0a58d27a-3569-4576-ab09-0f798d84d200
    return this.http.post(url, {}, { headers }).toPromise();
  }

  public pendingCollaboratorTeebCare(idCollaborator){
    
    const token = localStorage.getItem('tokenCare');
    const organization = localStorage.getItem('organization');
    const headers = {
      Authorization: token,
      organization: organization
    };
    const url = `${this.URL}/pending-collaborator-teeb-care/collaborator/${idCollaborator}`;

    return this.http.get(url,{ headers }).toPromise();
    //http://localhost:5000/api/pending-collaborator-teeb-care/collaborator/79
  }

  public getCheckQsessionTeeb(idUser) {
    const idOrganization = localStorage.getItem('organization');
    const url = `${this.appApiUrlHealth}q/check-qsession-teeb/${idUser}/${idOrganization}`;
    return this.http.get(url).toPromise();
  }
}
