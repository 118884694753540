import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BeneficiariesService } from 'src/app/pages/beneficiaries/beneficiaries.service';
import { ComingSoonMessageComponent } from 'src/app/dialogs/coming-soon-message/coming-soon-message.component';


@Component({
  selector: 'app-static-counters-profile',
  templateUrl: './static-counters-profile.component.html',
  styleUrls: ['./static-counters-profile.component.scss']
})
export class StaticCountersProfileComponent implements OnInit {
  public idBeneficiaries;
  public speciality:any;
  public type:any;

  public appoimentGeneral:string;
  public appoimentNutritionist:string;
  public appoimentPsychology:string;
  public digitalProfileStatistics = [];
  public statistics = [];

  constructor(
    private router: Router,
    private beneficiariesService: BeneficiariesService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getStatistics();
    this.idBeneficiaries = this.router.url.split('/')[3];
    this.getConsultationSpeciality();
    this.getSupervisorTeebCarePatientData();
  }

  public async getConsultationSpeciality(){
    try {
      const response:any = await this.beneficiariesService.getConsultationsSpecialtybyUser(this.idBeneficiaries);
      if(response){
        const { speciality, type } = response.consultations;
        this.speciality = speciality;
        this.type = type;
        this.assigningValuesCards(this.type);
      }
      
    } catch (error) {
      
    }
  }

  public async getSupervisorTeebCarePatientData(){
    try {
      const response: any = await this.beneficiariesService.supervisorTeebCarePatientData(this.idBeneficiaries);
      if(response.status === 'ok'){
        const { appoiment_general,appoiment_nutritionist, appoiment_psychology } = response.results;
        this.appoimentGeneral = appoiment_general;
        this.appoimentNutritionist = appoiment_nutritionist;
        this.appoimentPsychology = appoiment_psychology;
        this.assigningValuesCards(this.type);
      }
    } catch (error) {
      
    }
  }

  public openDilaogSoon(){
    this.dialog.open(ComingSoonMessageComponent, {
      width: '400px'
    });
  }

  public executeFunction(item:any){
    if('openDilaogSoon' === item.function) {
      this.openDilaogSoon();
    }
  }

  public assigningValuesCards(ObjectValue){
    if(ObjectValue){
      const arrayValues = Object.keys(ObjectValue);
      for(let i = 0; i < this.statistics.length; i++){
        for(let j = 0; j < arrayValues.length; j++){
          const element = arrayValues[j];
          if(this.statistics[i].id === element){
            this.statistics[i].value = ObjectValue[element];
          }
        }
      }
      this.digitalProfileStatistics = this.statistics;
    }

  }

  public getStatistics() {
    const organizationData = JSON.parse(localStorage.getItem('organizationData'));
    this.statistics = organizationData.organization.web_config?.statistics.digitalProfileStatistics;
  }

}
