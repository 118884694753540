import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private URL: string =   environment.appApiUrl;

  public token: string = '';

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  get getUser() {
    return (this.token = localStorage.getItem('tokenCare') || '');
  }

  get isLogged(): boolean {
    const token = this.getUser;
    return token ? true : false;
  }

  public login(email: string, pass: string){

    const URL = `${this.URL}/supervisor/login`;
    const body = {
      email,
      pass
    };

    return this.http.post(URL, body).toPromise();
    
  }

  public recovery(email:string, url: string){

    const URL = `${this.URL}/supervisor/recovery-email-pass`;
    const body = {
      email,
      domain: url
    };

    return this.http.post(URL , body).toPromise();
  }

  public verifyTokenInvitation(token: string) {
    const URL = `${this.URL}/invitation-supervisor/validate/${token}`;
    return this.http.get(URL).toPromise();
  }

  public sendPhoneVerification (phone: string, invitationToken: string) {
    const URL = `${this.URL}/supervisor/sendVerySMS`;
    const body = {
      tel: phone,
      token: invitationToken
    };
    return this.http.post(URL, body).toPromise();
  }

  public verifyCodePhone(code:string, phone:string, invitationToken:string, country:string ) {
    const URL = `${this.URL}/supervisor/createPhone`;
    const body = {
      code_tel: country,
      code,
      tel: phone,
      token: invitationToken
    };
    return this.http.post(URL, body).toPromise();
  }

  public createAccount(email: string,name: string, lastName: string, password: string, invitationToken: string) {
    const URL = `${this.URL}/supervisor/createUser`;
    const body = {
      email,
      last_name: lastName,
      name,
      pass:password,
      token: invitationToken
    };
    return this.http.put(URL, body).toPromise();
  }

  public changePassword(password:string, token: string) {
    const URL = `${this.URL}/supervisor/change-pass-email`;
    const body = {
      pass: password,
    };
    const headers = {
      Authorization: token,
    };
    return this.http.post(URL, body,{headers}).toPromise();
  }

  public logout(){
    localStorage.removeItem('tokenCare');
    localStorage.removeItem('profile');
    localStorage.removeItem('organizationData');
    localStorage.removeItem('organization');
    localStorage.removeItem('beneficiarieSelect');
    return this.router.navigate(['/auth']);
  }

  
}
