<h2 mat-dialog-title>Editar supervisor</h2>
<section style="width: 100%;">
    <form class="width-100" [formGroup]="EditProfileForm">
        <mat-form-field color="accent" class="width-100" appearance="outline">
            <mat-label>
                Nombre
            </mat-label>
            <input type="text" formControlName="name" matInput required />
            <mat-error>
                
                <ng-container *ngIf="EditProfileForm.get('name')?.hasError('required')">
                    El nombre es requerido
                </ng-container>
            </mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="width-100" appearance="outline">
            <mat-label>
                Apellido
            </mat-label>
            <input type="text" formControlName="lastName" matInput required />
            <mat-error>
                
                <ng-container *ngIf="EditProfileForm.get('lastName')?.hasError('required')">
                    El Apellido es requerido
                </ng-container>
            </mat-error>
        </mat-form-field>
        <button class="button" mat-raised-button (click)="editProfile()">
            Guardar
        </button>
    </form>
</section>