import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup,  Validators, FormBuilder, FormControl } from '@angular/forms';
import { ProfileService } from '../profile.service';
import { PassDataService } from 'src/app/shared/services/pass-data.service';


@Component({
  selector: 'app-edit-profile-supervisor',
  templateUrl: './edit-profile-supervisor.component.html',
  styleUrls: ['./edit-profile-supervisor.component.scss']
})
export class EditProfileSupervisorComponent implements OnInit {
  public EditProfileForm: FormGroup;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private form: FormBuilder,
    public profile: ProfileService,
    private passDataService: PassDataService,
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    this.EditProfileForm = this.form.group({
      name: [this.data.name, Validators.required],
      lastName: [this.data.last_name, Validators.required],
    });
  }

  public async editProfile() {
    try {

      const { name, lastName } = this.EditProfileForm.value;
      const response: any = await this.profile.editProfileSupervisor(this.data.uuid, name, lastName );
      if(response === null){
        this.dialog.closeAll();
      }
      
    } catch (error) {
      
    }
  }

}
