import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PdfFormatsService {

  private URL: string = environment.rootUrl;

  constructor(
    private http: HttpClient
  ) { }

  public getPDFFormats(organizationId: string) {
    const URL = `${this.URL}/v1.2/api/bucket/organization/public/${organizationId}`;
    return this.http.get(URL).toPromise();
  }


  public downloadDocument(keyAws: string){
    const URL = `${this.URL}/v1.2/api/bucket/getFilePublic/${keyAws}`;
    return this.http.get(URL).toPromise();
  }
}
