import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AuthorizatedAdminGuard  } from './guards/authorizated-admin.guard';
import { NoAuthorizatedAdminGuard  } from './guards/no-authorizated-admin.guard';
import { ProfileComponent } from './pages/profile/profile.component';
import { PdfFormatsComponent } from './pages/pdf-formats/pdf-formats.component';

const routes: Routes = [
  {
    path: 'formats/:id',
    component: PdfFormatsComponent
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
    canActivate: [NoAuthorizatedAdminGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthorizatedAdminGuard],
  },
  {
    path: 'beneficiaries',
    loadChildren: () => import('./pages/beneficiaries/beneficiaries.module').then(m => m.BeneficiariesModule),
    canActivate: [AuthorizatedAdminGuard],
  },
  {
    path: 'supervisors',
    loadChildren: () => import('./pages/supervisors/supervisors.module').then(m => m.SupervisorsModule),
    canActivate: [AuthorizatedAdminGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthorizatedAdminGuard],
  },
  {
    path: '**',
    redirectTo: 'auth',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
