<div mat-dialog-content>
    <div class="description">
        <span>
            No tiene ninguna organización asociada por favor comuníquese con el administrador.
        </span>
    </div>

    <div class="tb-row tb-justify-center">
        <button class="buttonExit" mat-raised-button (click)="logout()" mat-dialog-close>
            Salir
        </button>
    </div>

</div>