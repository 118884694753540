import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocaleDatetimePipe } from './locale-datetime.pipe';
import { CardNumberPipe } from './card-number.pipe';



@NgModule({
  declarations: [
    LocaleDatetimePipe,
    CardNumberPipe
  ],
  exports:[
    LocaleDatetimePipe,
    CardNumberPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
