import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SupervisorsService } from 'src/app/pages/supervisors/supervisors.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { SNACKBAR_RESULT } from 'src/app/shared/data/data';

@Component({
  selector: 'app-delete-invitation',
  templateUrl: './delete-invitation.component.html',
  styleUrls: ['./delete-invitation.component.scss']
})
export class DeleteInvitationComponent implements OnInit {
  public error: boolean = false;
  public messageError: string = '';
  public isLoading: boolean = false;


  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private supervisorsService: SupervisorsService,
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
  ) {
    this.data = data;
  }

  ngOnInit(): void {
  }

  public async deleteInvitation() {
    try {
      this.isLoading = true;
      const response: any =  await this.supervisorsService.deleteInvitations(this.data.uuid);
      if (response === null) {
        this.dialog.closeAll();
        this.snackbarService.openSnackBar('Se ha eliminado correctamente.',SNACKBAR_RESULT.success);
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      if(error.error.code){
        this.error = true;
        const { code } = error.error;
        if(code === 0){
          this.messageError = 'Esta cuenta de supervisor no es valida. Prueba con otra.';
        } else if (code === 1){
          this.messageError = 'La organización no es valida. Prueba con otra.';
        } else if (code === 2){
          this.messageError = 'No puedes borrar tu propia cuenta. Prueba con otra.';
        }  else if (code === 3){
          this.messageError = 'Hubo un problema. Prueba más tarde.';
        }
      }
      this.snackbarService.openSnackBar('Hubo un problema al eliminar, intenta de nuevo más tarde.',SNACKBAR_RESULT.fail);
    }
  }

}
