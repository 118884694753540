import { Pipe, PipeTransform } from '@angular/core';
/* import moment from 'moment'; */
var moment = require('moment');
@Pipe({
  name: 'localeDatetime'
})
export class LocaleDatetimePipe implements PipeTransform {

  transform(value: any, format: string = 'DD MMM YYYY hh:mm a') {
    if(value){
      return moment(value).locale('es').format(format);
    }else {
      return 'Sin fecha'
    }
  }

}
