import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SubSink } from 'subsink';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { PassDataService } from 'src/app/shared/services/pass-data.service';
import { PdfPreviewComponent } from 'src/app/dialogs/pdf-preview/pdf-preview.component';
import { BeneficiariesService } from 'src/app/pages/beneficiaries/beneficiaries.service';


@Component({
  selector: 'app-health-traffic-light-home',
  templateUrl: './health-traffic-light-home.component.html',
  styleUrls: ['./health-traffic-light-home.component.scss']
})
export class HealthTrafficLightHomeComponent implements OnInit {
  private appApiUrlHealth: string = environment.appApiUrlHealth;
  @ViewChild('ContentIframe') ContentIframe: ElementRef;
  public url: string;
  public subs = new SubSink();
  public isLoading: boolean = true;
  public dialogRef: any;
  public urlResultRisk: any;
  public urlResultRiskSanitized: any;
  public existsReport: boolean = false;

  constructor(
    private renderer: Renderer2,
    private passDataService: PassDataService,
    private dialog: MatDialog,
    private beneficiariesService: BeneficiariesService,
    private sanitizer: DomSanitizer
  ) {
    this.subs.add(
      this.passDataService.currentMessage.subscribe(message => {
        if (message === 'selectOrganization') {
          this.showPage();
          this.showButtonreport();

        }
      })
    )
  }

  ngOnInit(): void {
    this.isLoading = true;
  }

  ngAfterViewInit(): void {
    this.showPage();
  }

  public showPage() {
    this.isLoading = true;
    try {
      const idOrganization = localStorage.getItem('organization');
      const asIframe = this.ContentIframe.nativeElement;
      const url = `${this.appApiUrlHealth}q/questionnaire/reporte-de-salud/${idOrganization}/?iframe=True`;
      this.renderer.setAttribute(asIframe, 'src', url);
      asIframe.onload = () => {
        this.isLoading = false;
      }

    } catch (error) {
      this.isLoading = false;
    }

  }

  public dialogPreviewPDF() {
    window.open(this.urlResultRisk, '_blank');
  }

  public showButtonreport(){
    const organizationData = JSON.parse(localStorage.getItem('organizationData'));
    if(organizationData.organization.result_risk){
      this.existsReport = true;
      this.urlResultRisk = organizationData.organization.result_risk;
    }else {
      this.existsReport = false;
    }
  }


}
