import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'HealthCareAdminWeb';

  public displayLayout: boolean = false;
  private subs = new SubSink();


  constructor(
    private router: Router,
    private location: Location,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer

  ) {
    this.subs.add(
      router.events.subscribe(() => {
        this.displayLayout = !(
          location.path().includes('/auth/login') ||
          location.path().includes('/auth/register') ||
          location.path().includes('/auth/invitation')||
          location.path().includes('/auth/recovery')||
          location.path() === ''
        )
      })
    );

    iconRegistry
      .addSvgIcon('icon-home', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-home.svg'))
      .addSvgIcon('ic-patients', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-patients.svg'))
      .addSvgIcon('icon-beneficiaries', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-beneficiaries.svg'))
      .addSvgIcon('icon-supervisors', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-supervisors.svg'))
      .addSvgIcon('icon-refresh', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-refresh.svg'))
      .addSvgIcon('icon-user-add', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-user-add.svg'))
      .addSvgIcon('icon-done', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-done.svg'))
      .addSvgIcon('icon-trash', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-trash.svg'))
      .addSvgIcon('icon-edit', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-edit.svg'))
      .addSvgIcon('icon-info', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-info.svg'))
      .addSvgIcon('icon-csv', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-csv.svg'))
      .addSvgIcon('ic-blood', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-sangre.svg'))
      .addSvgIcon('ic-height', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-estatura.svg'))
      .addSvgIcon('ic-scale-w', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-bascula.svg'))
      .addSvgIcon('ic-scale', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-pesa.svg'))
      .addSvgIcon('ic-open-list', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-open-list.svg'))
      .addSvgIcon('ic-close-list', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-close-list.svg'))
      .addSvgIcon('icon-stethoscope', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-stethoscope.svg'))
      .addSvgIcon('icon-brain', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-brain.svg'))
      .addSvgIcon('icon-apple', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-apple.svg'))
      .addSvgIcon('icon-search', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-search.svg'))
      .addSvgIcon('icon-profile', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-profile.svg'))
      .addSvgIcon('icon-security', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-security.svg'))
      .addSvgIcon('icon-messages', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-messages.svg'))
      .addSvgIcon('icon-email', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-email.svg'))
      .addSvgIcon('icon-whatsApp', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-whatsApp.svg'))
      .addSvgIcon('icon-crossout', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-crossout.svg'))
      .addSvgIcon('ic-copy', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-copy.svg'))
      .addSvgIcon('icon-document-download', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-document-download.svg'))
      .addSvgIcon('icon-google', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-google.svg'))
      .addSvgIcon('icon-phone', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-phone.svg'))
      .addSvgIcon('icon-delivery', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-delivery.svg'))
      .addSvgIcon('icon-financing', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-financing.svg'))
      .addSvgIcon('icon-discount', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-discount.svg'));
  }

  ngOnInit(): void {
  }
}
